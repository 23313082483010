import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import About from "../views/About.vue";
import Login from "../views/Login.vue";
import Feedback from "../views/Feedback.vue";
import Register from "../views/Register.vue";
import ResetPassword from "../views/ResetPassword.vue";
import VerifyAccount from "../views/VerifyAccount.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Attribution from "../views/Attribution.vue";
import Join from "../views/Join.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    },
    {
        path: "/forgot-password",
        name: "Reset Password",
        component: ResetPassword,
        meta: {
            guest: true
        }
    },
      {
        path: "/profile",
        name: "Profile",
        meta: {
            requiresAuth: true
        },
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: {
            requiresAuth: true
        }
    },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
        guest: true
    }
    },{
    path: "/license",
    name: "Attribution",
    component: Attribution,
    meta: {
        guest: true
    }
    },
    {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
        requiresAuth: true
    }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            guest: true
        },

    },
     {
        path: "/join",
        name: "Join",
        component: Join,
        meta: {
            guest: true
        },

    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            guest: true
        }
    }, {
        path: '/verification',
        name: 'VerifyAccount',
        component: VerifyAccount,
        meta: {
            guest: true
        }
    },
    {
        path: '/:notFound(.*)*',
        name: "error_404",
        component: PageNotFound
    }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('jwt') == null){
            localStorage.removeItem('user');
            next({ name: 'Login' })
        }else{
            next();
        }
    }else {
        next();
    }
})
export default router;
