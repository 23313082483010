<template>
    <div id="aj" v-if="gameStarted">      
        <div class="round-init" v-if="initTurnSelection">
                <div v-if="host">
                    <div v-if="!TurnWinnerRule">
                             <p>Game Setup</p>
                              <button type="button" class="btn btn-block btn-primary" @click="useDefaultRules()" title="Use Default Setup">Default</button>
                        <hr>
            
                        <span class="blocking">
                            <em>Block rule <i title="Block players with 2(two) or more pieces on 1(one) block." class="fa fa-exclamation"></i></em><br>
                            <button type="button" :class="['btn', !rule_store['BlockGame'] ? 'btn-primary' : 'btn-danger' ]" @click="setGateRule(2)" title="Open Gate: Don't Block"><i class="fa fa-lock-open"></i></button>&nbsp;
                            <button type="button" :class="['btn', rule_store['BlockGame'] ? 'btn-primary' : 'btn-danger' ]" @click="setGateRule(1)" title="Lock Gate: Block"> <i class="fa fa-lock"></i></button>
                        </span>
                      
                        <hr>
                        <em>Set turn rule.<i class="fa fa-exclamation" title="This determines the player who will go first"></i></em><br>
                        <button type="button" :class="['btn', rule_store['MinThrow'] ? 'btn-primary' : 'btn-danger' ]" @click="setTurnRule(1)" title="Smallest Roll"><i class="fa fa-long-arrow-alt-down"></i><i class="fa fa-dice-one"></i></button>
                         &nbsp;<button type="button" :class="['btn', rule_store['MaxThrow'] ? 'btn-primary' : 'btn-danger' ]" @click="setTurnRule(2)" title="Largest Roll"><i class="fa fa-long-arrow-alt-up"></i><i class="fa fa-dice-six"></i></button>
                        <div>&nbsp;</div>

                        <hr>
                        <span class="dices">
                            <em># Die to use <i title="Single Die or Double Dice" class="fa fa-exclamation"></i></em><br>
                            <button type="button" :class="['btn', !rule_store['DoubleDice'] ? 'btn-primary' : 'btn-danger' ]" @click="setDiceRule(1)" title="Single Dice"> <i class="fa fa-dice-one"></i></button>&nbsp;
                            <button type="button" :class="['btn', rule_store['DoubleDice'] ? 'btn-primary' : 'btn-danger' ]" @click="setDiceRule(2)" title="Double Dice"><i class="fa fa-dice"></i></button>
                        </span>
                        
                        <hr>
                        <span class="setrule">
                            <button type="button" id="confirm-rules" class="btn btn-block btn-danger" @click="updateGameRules(false)" title="Confirm settings"><i class="fa fa-check"></i></button>
                        </span>
                        <div>&nbsp;</div>
                      </div>
                </div>
                <div v-if="TurnWinnerRule">
                    <em class="rule">{{ rules[TurnWinnerRule] }}</em><br>
                    <button type="button" id="dice-roller" :class="['box-btn dice',player.PlayerTurn ? 'clickable':'']" v-if="TurnWinnerRule && !rollState" :disabled="!player.PlayerTurn" @click="initDiceRoll">
                        <i :class="'fas fa-dice-' + diceFaces[parseInt((Math.random() * 6)) + 1]"></i>
                    </button>
                </div>
                <div v-else>
                    <p v-if="!host">Waiting for host to complete setup......</p>
                </div>
        </div>

        <div v-if="!showAllPlayers" class="show-turn">
            <div class="text-left open-players-dialog" @click="openShowPlayers" title="Show all players">
                <i class="fa fa-user"></i>
            </div>
            <div >
                <span v-for="player_ in players" :key="player_.id">
                    
                        <span class="show-player-turn turn" v-if="player_.PlayerTurn">
                            <span class='player-img'>
                                <img :src="player_.Avatar ? $imgPath + player_.Avatar : '/img/avatar.png'" />
                            </span> 
                            <span class="player-name" v-if="player_.UserId!=user.UserId" :title="player_.UserName">{{ player_.UserName }}</span>
                            <span class="player-name" v-else>Me</span>
                        </span>
                    
                </span>
            </div>
        </div>

        <div class="in-game-players" v-if="showAllPlayers">
            <div class="container justify-content-center " v-for="player_ in players" :key="player_.id">
            <div class="card mt-1 p-1" :class="player_.PlayerTurn ? 'turn' : 'no-turn'">
              <div class="media"> 
                <span class='in-game-player-avatar'>
                    <img :src="player_.Avatar ? $imgPath + player_.Avatar : '/img/avatar.png'" />
                </span> 
                <span class="player-name" v-if="player_.UserId!=user.UserId" :title="player_.UserName">{{ player_.UserName }}</span>
                <span class="player-name" v-else>Me</span>
                   
                <transition mode="out-in" name="dice" appear>
                    <span v-if="player_.PlayerTurn" class="player-dice-show">
                        <span v-if="gameState.Round > 0">
                            <span v-if="gameState.GameRule.DoubleDice">
                                <Dice :roll="true" size="20" :rolled="dieRoll1"></Dice>
                                <Dice :roll="true" size="20" :rolled="dieRoll2"></Dice>
                            </span>
                            <span v-else>
                                <Dice :roll="true" size="20" :rolled="dieRoll1"></Dice>
                            </span>
                        </span>
                        <Dice v-else :roll="true" size="20" :rolled="dieRoll1"></Dice>
                    </span>
                    <span v-else>
                        <span v-if="player_.DiceRoll">
                            <span v-if="player_.DiceRoll.length > 0">
                                <Dice :roll="true" size="20" :rolled="player_.DiceRoll[0]"></Dice>
                                <Dice v-if="player_.DiceRoll.length > 1" :roll="true" size="20" :rolled="player_.DiceRoll[1]"></Dice>
                            </span>
                        </span>
                    </span>
                </transition>
                </div>
              </div>

            </div>
            <span class="float-left pw" @click="closeShowPlayers"><i class="fa fa-user-slash"></i></span>
  
          </div>
       
        <span @click="openSpectators" v-if="!showSpectators && spectators.length > 0" class="float-right pw"><i class="fa fa-eye"></i></span>
        <div class="in-game-spectators" v-if="showSpectators">
            <span @click="closeSpectators" class="float-right" title="close spectator window"><i class="fa fa-eye-slash"></i></span>
            <div class="container d-flex justify-content-center" v-for="spectator in spectators" :key="spectator.id">
            <div class="card mt-1 p-1">
              <div class="media"> 
                <span class='in-game-spectator-avatar' :title="spectator.UserName">
                    <img :src="spectator.Avatar ? $imgPath + spectator.Avatar : '/img/avatar.png'" />
                </span> 
                </div>
              </div>
            </div>
        </div>
      

    <div v-if="confirmEndGameSession" class="confirm">
          <h3>Confirm!!!</h3>
          <h4> Close Game Session?</h4>
          <p>You have guests in this room.</p>
          <p>Are you sure you want to complete this action?</p>
          <button type="button" class="btn btn-danger" @click="endGameSession()">Yes</button>&nbsp;
          <button type="button" class="btn btn-success" @click="cancelClose()">No</button>
      </div> 

        <div v-if="gameState.GameRule" class="game-rules">
            <span class="rules">
                <em> Blocking: <span>{{ gameState.GameRule.BlockGame }}</span></em>
                <em> Players: <span>{{ gameState.NumOfPlayers }}</span></em> 
                <em> Spectators: <span>{{ spectators.length }}</span></em> 

                <em v-if="prevPlayer"> previous: <span> {{ prevPlayer.UserName }}</span></em>
                <em v-if="prevPlayer"> Throw: 

                    <span v-if="prevPlayer.DiceRoll">
                        <span v-if="prevPlayer.DiceRoll.length > 0">
                            <Dice :roll="true" size="20" :rolled="prevPlayer.DiceRoll[0]"></Dice>
                            <Dice v-if="prevPlayer.DiceRoll.length > 1" :roll="true" size="20" :rolled="prevPlayer.DiceRoll[1]"></Dice>
                        </span>
                    </span>
                </em>
                <em v-if="currentPlayer"> current: <span> {{ currentPlayer.UserName }}</span></em>
                <em v-if="currentPlayer"> Throw:

                    <span v-if="currentPlayer.DiceRoll">
                        <span v-if="currentPlayer.DiceRoll.length > 0">
                            <Dice :roll="true" size="20" :rolled="currentPlayer.DiceRoll[0]"></Dice>
                            <Dice v-if="currentPlayer.DiceRoll.length > 1" :roll="true" size="20" :rolled="currentPlayer.DiceRoll[1]"></Dice>
                        </span>
                    </span>
                </em>
            </span>
        </div>
 
        <span class="leave-game" v-if="host" @click="cancelGame" title="Cancel Game">
            <i class="fa fa-trash"></i>
        </span>



        <div :class="['board',theme]">
            <div id="homes">
                <span v-for="i in 4" :class="['p_home','home','player_'+i]" :ref="`path_${i}000`" :key="'player_home_'+i">
                    <span class="homed-piece-container">
                        <span v-for="piece in homed_pieces[i]" :key="piece.id" class="homed-piece">
                            <i class="fa fa-circle"></i>
                        </span>
                    </span>
                </span>
            </div>
            <div :class="['home_'+i,'player-box']" :style="'transform: rotate('+home_position[(i-1)]+'deg);'" v-for="i in 4" :key="i">
                <div class="player-start-box" :ref="`home_${i}000`">
                    <span v-if="players[(i-1)]" class="home-piece">
                        <span class="home-name">{{ players[(i-1)].UserName == $user.UserName ? 'Me' : players[(i-1)].UserName }}</span>
                        <span v-for="(piece,index) in players[(i-1)].Pieces" :key="piece.id" :class="['pieces','player_'+i]">
                            
                            <button type="button" v-if="piece.Position < 1" :key="'piece_'+index" :disabled="players[(i-1)].PlayerYardPosition < 1" :class="['btn btn-small pie', players[(i-1)].PlayerYardPosition > 0 ? 'piece': '',showTurn(players[(i-1)])]" @click="bornPlayerPiece(piece)"><i class="fa fa-circle"></i>
                            </button>
                        </span>
                    </span>
                </div>
              
                <div class="first-trail">
                    <span v-for="path in $ludi_board_plot[(i-1)][0]" :key="'path_'+path" :class="[$piece_direct_path[(i-1)][0] === path ? 'gate':'first-trail-path','path',pathBlocked(path)]" :ref="'path_' + path" :title="path">
                        <span v-for="piece in active_pieces" :key="piece.id">
                            <transition name="piece-walk" mode="out-in" appear>
                                <span v-if="piece.Position < 53">
                                    <span v-if="$piece_direct_path[(piece.Player - 1)][(piece.Position - 1)] === path" :class="['piece-on-path','player_'+piece.Player,piece.EntityId == piece_selected ? 'selected' : '']" >
                                        <i class="fa fa-circle"></i>
                                    </span>
                                </span>
                            </transition>
                        </span> 
                    </span>
                </div>
                <div class="second-trail">
                    <span  v-for="path in $ludi_board_plot[(i-1)][1]" :key="'path_'+path" :class="['path','player_'+i,'second-trail-path',pathBlocked(path)]" :ref="'path_' + path" :title="path">
                        <span v-for="piece in active_pieces" :key="piece.id">
                            <transition name="piece-walk" mode="out-in" appear>
                                <span v-if="piece.Position < 53">
                                    <span v-if="$piece_direct_path[(piece.Player - 1)][(piece.Position - 1)] === path" :class="['piece-on-path','player_'+piece.Player,piece.EntityId == piece_selected ? 'selected' : '']">
                                        <i class="fa fa-circle"></i>
                                    </span>
                                </span>
                            </transition>
                        </span> 
                    </span>
                </div>
                <div class="player-home-box">
                    <span v-for="path in $ludi_board_plot[(i-1)][2]" :class="['path','player_'+i,'home-walk',pathBlocked(path)]" :key="'path_'+path" :ref="'path_' + path" :title="path">
                        <span v-for="piece in active_pieces" :key="piece.id">
                            <transition name="piece-walk" mode="out-in" appear>
                                <span v-if="piece.Position < 53">
                                    <span v-if="$piece_direct_path[(piece.Player - 1)][(piece.Position - 1)] === path" :class="['piece-on-path','player_'+piece.Player,piece.EntityId == piece_selected ? 'selected' : '']">
                                        <i class="fa fa-circle"></i>
                                    </span>
                                </span>
                            </transition>
                        </span> 
                    </span>
                </div>
            </div>
        </div>

<div id="winner" v-if="gameComplete">
 <div :class="['modal',gameComplete ? 'open':'']" role="dialog">
     <div class="modal-dialog">
         <div class="card">
             <div class="text-right cross" @click="closeWinner()"> <i class="fa fa-times"></i> </div>
             <div class="card-body text-center" v-if="gameWinner && gameWinner.UserName == player.UserName"> 
                <img src="@/assets/images/ludi.png" />
                 <h4>Winner!!!!</h4>
                 <p>You won the game, your score will be updated on the leader board.</p> 
                 <p v-if="host"><button class="btn btn-success" @click="continueGame()">Play Again</button>&nbsp;<button class="btn btn-warning" @click="cancelGame()">End Game</button></p>
                 <p v-else class="text-left">Waiting on host to take action.... <br>Communicate with host to restart / end game. / <button class="btn btn-info" @click="playerLeaveGame()">Leave Game</button></p>

                 <!-- <p v-else class="text-left"><button class="btn btn-info" @click="StayInGame()">Stay</button> - Waiting on host to take action.  <br/><br/><button class="btn btn-warning" @click="leaveGame()">Leave</button> - Exit game</p> -->
             </div>
              <div class="card-body text-center" v-else> 
                <img src="@/assets/images/ludi.png" />
                 <h4>You Lost!!!</h4>
                 <p v-if="gameWinner">Player {{ gameWinner.PlayerPosition }} aka {{ gameWinner.UserName }} won the game, scores will be updated on the leader board</p> 
                 <p v-if="host"><button class="btn btn-success" @click="continueGame()">Play Again</button>&nbsp;<button class="btn btn-warning" @click="cancelGame()">End Game</button></p>
                 <p v-else class="text-left">Waiting on host to take action.... <br/>Communicate with host to restart / end game.</p>
                 <!-- <p v-else class="text-left"><button class="btn btn-info" @click="StayInGame()">Stay</button> - Waiting on host to take action.  <br/><br/><button class="btn btn-warning" @click="leaveGame()">Leave</button> - Exit game</p> -->
             </div>
         </div>
     </div>
 </div>
</div>

        <div v-if="showDiceRoll" ref="rolling" class="rolling">
            <transition name="diceroll" @enter="animateDiceRoll" mode="out-in" appear>
                <div v-if="currentDiceRoll">
                    <Dice :roll="true" size="60" :rolled="dieRoll1" :used="die_used == 1 || die_used == 2"></Dice>
                    <Dice v-if="currentDiceRoll.length > 1" :roll="true" size="60" :rolled="dieRoll2" :used="die_used == 1 || die_used == 3"></Dice>
                </div>
            </transition>
        </div>

        <div v-if="showInitDiceRoll" ref="rolling" class="rolling">
            <transition name="dice-roll" @enter="initAnimateDiceRoll" mode="out-in" appear>
                <div v-if="currentDiceRoll">
                    <Dice :roll="true" size="60" :rolled="dieRoll1" :used="die_used == 1 || die_used == 2"></Dice>
                    <Dice v-if="currentDiceRoll.length > 1" :roll="true" size="60" :rolled="dieRoll2" :used="die_used == 1 || die_used == 3"></Dice>
                </div>
            </transition>
        </div>
        <!-- end board definition  -->
        <div v-if="IamOffline && offlinePlayers.length < 1">
            <div>You are disconnected refresh to reconnect:</div>
        </div>
        <div v-if="offlinePlayers.length > 0" class="gamecontrols text-white">
            <div>Waiting for players to reconnect:</div>
            <ul>
                <li></li>
                <li v-for="offliner in offlinePlayers" :key="offliner.UserName">
                    <span v-if="offliner.UserName != player.UserName" class="waiting">{{offliner.UserName}}...</span>
                </li>
            </ul>
        </div>
        <div  v-else class="gamecontrols">
            <button v-if="!diceRoll && gameState.GameRule" type="button" id="dice" :class="['btn', 'btn-default', 'float-left',player.PlayerTurn ? 'clickable':'']" :disabled="!player.PlayerTurn || initTurnSelection || player.PlayerYardPosition > 0 || moveActivePiece" @touch:tap="toastTap" @click="inGameDiceRoll">
                <i  v-if="gameState.GameRule.DoubleDice" class="fas fa-dice fa-3x"></i>
                <i  v-else :class="['fas', 'fa-dice-' +  diceFaces[single_die_face], 'fa-3x']"></i>
            </button>

            <ul>
                <li v-for="piece in player.Pieces" :key="piece.id">
                    <button type="button" :class="['btn btn-default',!piece.PieceSelected && moveActivePiece ? 'piece-mover':'',piece.PieceSelected ? 'selected-piece' : '', piece.PieceDetained ? 'penalty': '']" :disabled="!moveActivePiece || piece.Position < 1 || !player.PlayerTurn || pieceAnimation || piece.Position >= 53 || !allowPlay || (piece.PieceCanMove != null && !piece.PieceCanMove)" @mouseleave="pieceSelection(piece,'out')" @mouseover="pieceSelection(piece,'in')" @click="getPieceOptions(piece)">
                        <i class="fa fa-circle fa-2x"></i>
                    </button>
                    <div class="piece-move-options" v-if="piece.PieceMoveOptions && player.PlayerTurn">
                        <ul v-if="gameState.GameRule.DoubleDice">
                            <li v-if="dieRoll1 && dieRoll2" @mouseleave="removePieceProjection(piece,1)" @mouseover="showPieceProjection(piece,1)" @click="movePlayerPiece(piece,1)">
                                Use Both&nbsp;&nbsp;
                                <span class="float-right">
                                    <Dice :roll=true size="10" :rolled="dieRoll1"></Dice>+
                                    <Dice :roll=true size="10" :rolled="dieRoll2"></Dice>
                                </span>
                            </li>
                            <li v-if="dieRoll1" @mouseleave="removePieceProjection(piece,2)" @mouseover="showPieceProjection(piece,2)" @click="movePlayerPiece(piece,2)">
                                Use Die &nbsp;
                                <span class="float-right">
                                    <Dice :roll=true size="10" :rolled="dieRoll1"></Dice>
                                </span>
                            </li>
                            <li v-if="dieRoll2" @mouseleave="removePieceProjection(piece,3)" @mouseover="showPieceProjection(piece,3)" @click="movePlayerPiece(piece,3)">
                                Use Die &nbsp; 
                                <span class="float-right">
                                    <Dice :roll=true size="10" :rolled="dieRoll2"></Dice>
                                </span>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

        <span class="ingame-tools">
            <!-- <p style="color:white;font-weight: bolder;">{{talkingScript}}</p> -->
            <button type="button" class="btn btn-default" v-if="player.PlayerTurn && moveActivePiece" @click="playerPassTurn()" title="Manually pass your turn. If you have no possible moves and your turn didnt pass automatically.">
                <i class="fa fa-forward"></i>
            </button>&nbsp;
            <button type="button" :class="['btn btn-default',audioMuted ? '' : 'mic-pulse','pulse']" :title="audioMuted ? 'Mic is Muted' : 'Mic is active'" @click="toggleMic" style="outline: none; border: none; cursor: pointer;">
                <i class="fa fa-microphone" style="color: rgba(100,100,200,1)" v-if="audioMuted"></i>
                <i class="fa fa-microphone fa fa-2x" v-else></i>
            </button>&nbsp;

            <button type="button" class="btn btn-default" v-if="gameStarted && !showGroupChat" :disabled="players.length < 2" @click="openGroupChat()">
                <i class="fa fa-comments"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-default float-right" v-if="showGroupChat" @click="closeGroupChat()">
                <i class="fa fa-comment-slash"></i> 
            </button>
        </span>
        </div>

        <button @click="diceRollout.play" ref="dice-roll-sound" class="soundfx"></button>
        <button @click="diceRollout.stop" ref="dice-roll-sound-stop" class="soundfx"></button>

        <button @click="chipMoveout.play" ref="chip-move-sound" class="soundfx"></button>
        <button @click="chipMoveout.stop" ref="chip-move-sound-stop" class="soundfx"></button>

        <button @click="optionSelectout.play" ref="option-select-sound" class="soundfx"></button>
        <button @click="optionSelectout.stop" ref="option-select-sound-stop" class="soundfx"></button>

        <button @click="notifyAlertout.play" ref="notify-alert-sound" class="soundfx"></button>
        <button @click="notifyAlertout.stop" ref="notify-alert-sound-stop" class="soundfx"></button>

        <button @click="clickOptionout.play" ref="click-option-sound" class="soundfx"></button>
        <button @click="clickOptionout.stop" ref="click-option-sound-stop" class="soundfx"></button>

        <button @click="homeMoveout.play" ref="home-move-sound" class="soundfx"></button>
        <button @click="homeMoveout.stop" ref="home-move-sound-stop" class="soundfx"></button>

        <button @click="killPieceout.play" ref="kill-piece-sound" class="soundfx"></button>
        <button @click="killPieceout.stop" ref="kill-piece-sound-stop" class="soundfx"></button>

        <transition mode="out-in" name="chat" appear>
            <div v-if="showGroupChat">
                <Chat :isInbox=false :groupMessages="group_messages" :personsInGame="gameState.Players" :isTyping="is_typing"></Chat>
            </div>
        </transition>

        <!-- end game control definition -->
    </div>
</template>

<script>
/* eslint-disable */
    import Dice from "../components/Dice.vue";
    import Chat from "../components/Chat.vue";
    import { useMeta, getCurrentManager } from 'vue-meta';
    import { useSound } from '@vueuse/sound';
    import diceRollfx from '@/assets/sounds/RollDice.mp3';
    import clickOptionfx from '@/assets/sounds/ClickOption.mp3';
    import homeMovefx from '@/assets/sounds/HomeMove.mp3';
    import chipMovefx from '@/assets/sounds/ChipMove.mp3';
    import notifyAlertfx from '@/assets/sounds/NotifyAlert.mp3';
    import optionSelectfx from '@/assets/sounds/OptionSelect.mp3';
    import killPiecefx from '@/assets/sounds/KillPiece.wav';
    import peer, { Peers } from '../plugins/PeerConnect';

    export default {
        name: "LudiBoard",
        props: ["userActive","online","theme"],
        components: {
            Dice,
            Chat,
        },
        created(){
            let board_ = this;
            peer.InitListeners(board_.$sigcon);
            board_.$sigcon.on('IJoinedGroup',(playerState) => {
                board_.internalPlayer = playerState;
                localStorage.setItem("playerState",JSON.stringify(board_.internalPlayer));
                board_.createAudioCreate();
            });
            
            board_.$sigcon.on('PlayerJoinedGroup',(player) => {
               board_.$toast.success(`${player.UserName} has joined the game.`);
            });
            
            if(board_.$sigcon.connectionState === 'Connected'){
                if(localStorage.getItem('gameSession')){
                    board_.createAudioCreate();
                }
            }

            window.addEventListener('IsTalking', (e) => {
                board_.talkingScript = e.detail;
                board_.$toast.show(`${e.detail} is talking...`,{
                        position: 'left'
                });
            });

            window.addEventListener('IsNotTalking', (e) => {
                board_.talkingScript = '';
            });
        },
        setup(){
            const diceRollout = useSound(diceRollfx);
            const chipMoveout = useSound(chipMovefx);
            const homeMoveout = useSound(homeMovefx);
            const clickOptionout = useSound(clickOptionfx);
            const optionSelectout = useSound(optionSelectfx); 
            const notifyAlertout = useSound(notifyAlertfx);
            const killPieceout = useSound(killPiecefx);
            
            useMeta({
              title: 'LudiJam - Board',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());


            return {
                diceRollout,chipMoveout,optionSelectout,notifyAlertout,clickOptionout,homeMoveout,killPieceout
            };
        },
        data() {
            return {
                home_starters: ["32", "43", "34", "23", "211", "312", "411", "310", "1112", "1011", "1211", "1110", "121", "123", "103", "114"],
                player: [],
                group_messages: {},
                user: this.$user,
                n_players: this.$n_players,
                players: [],
                spectators: [],
                player_throw: {}, 
                closed_onliners: false,
                host: false,
                rules: ["No rule set","Smallest roll wins the turn","Largest roll wins the turn"],
                home_position: [0,90,-180,-90],
                is_typing: "",
                has_active_piece: null,
                double_six: null,
                any_six: null,
                die_used: 0,
                all_die_used: [],
                pass_turn: false,
                active_pieces: [],
                homed_pieces: {1:[],2:[],3:[],4:[]},
                piece_selected: null,
                single_die_face: parseInt((Math.random() * 6)) + 1,
                blocked_paths: {},
                kill_piece: false,
                posible_moves: 0,
                rule_store: {BlockGame: true, DoubleDice: true, KillToken: true, MaxThrow: true, MinThrow: false}, 
                showSpectators: false,
                showAllPlayers: false,
                gameStarted: false,
                gameComplete: false,
                gameWinner: null,
                moveActivePiece: false,
                confirmEndGameSession: false,
                currentPlayer: null,
                gameState:  null,
                showDiceRoll: false,
                showInitDiceRoll: false,
                prevPlayer: null,
                diceRoll: false,
                dieRoll1: null,
                dieRoll2: null,
                sessionStatus: null,
                setTurnWinnerRule: true, 
                TurnWinnerRule: null,
                initTurnSelection: false,
                gameRules: {},
                initRoll: null,
                minThrowsToSpawn: parseInt((Math.random() * 7)) + 1,
                throwsCounter:0,
                rollState: null,
                diceFaces: ["","one","two","three","four","five","six"],
                optionAxios: this.$httpOptions,
                showPlayerTurn: null,
                showGroupChat: false,
                currentDiceRoll: null,
                pieceMoved: false,
                pieceKilled: false,
                pieceToMove: null,
                pieceAnimation: false,
                gameSession: localStorage.getItem('gameSession') ? localStorage.getItem('gameSession') : null,
                internalPlayer: null,
                audioMuted: true,
                initAudio: true,
                talkingScript: '',
                onlinePlayers: this.online,
                allowPlay: true,
                offlinePlayers: [],
                determinedBlock: null,
                IamOffline: false,
            }
        },
        mounted() {
                let board_ = this;
                this.$sigcon.on("IsTyping",function(typer_){
                  board_.is_typing = typer_;
                  setTimeout(()=>{
                    board_.is_typing = "";
                  },1000);
                });
                this.$sigcon.on("GameMessage", function(message){
                    if(!board_.showGroupChat)
                        board_.$refs["notify-alert-sound"].click();
                    
                    board_.group_messages[message.MessageId] = message;
                    board_.showGroupChat = true;
                    board_.scrollToLastMessage();
                })
                this.$http.get("/game/GetInitiatedSessions").then(session=>{
                    let gameState = session.data.Data;
                    board_.gameState = gameState;
                    board_.updateGameState(true);
                }).catch(()=>{
                    //this.Logout();
                });
                this.$sigcon.on("PlayerDisconnected",function(player){
                    if(board_.players.length){
                        let player_in_game = false;
                        board_.players.forEach((player_)=>{
                            if(player_.UserName == player.UserName){
                               player_.PlayerStatus = player.PlayerStatus;
                               player_in_game = true;
                               return true;
                            }
                        });
                        if(board_.offlinePlayers.length){
                            let in_array = false;
                            board_.offlinePlayers.forEach((offliner)=>{
                                if(offliner.UserName == player.UserName){
                                    in_array = true;
                                    return;
                                }
                            });
                            if(!in_array && player_in_game)
                                board_.offlinePlayers.push(player);

                        }else{
                            if(player.UserName != board_.player.UserName){
                                if(player_in_game)
                                    board_.offlinePlayers.push(player);
                            }else{
                                board_.IamOffline = true;
                            }
                        }
                    }
                });
                this.$sigcon.on("PlayerConnected",function(player){
                    if(board_.players.length){
                        board_.players.forEach((player_)=>{
                            if(player_.UserName == player.UserName){
                               player_.PlayerStatus = player.PlayerStatus;
                            }
                            if(board_.offlinePlayers.length){
                                board_.offlinePlayers = board_.offlinePlayers.filter((offliner)=>{
                                    return offliner.UserName != player.UserName;
                                });
                            }
                            board_.IamOffline = false;
                        });
                    }
                });
                this.$sigcon.on("BroadCastGameSession",(session)=>{
                    board_.gameState = session;
                    board_.updateGameState(false);
                });

                this.$sigcon.on("DeleteInvitations",function(session){
                    if(!board_.host){
                        board_.$toast.warning("Host has cancelled this game. Will reload in a second",{
                            duration: false,
                            dismissible: true
                        });
                    }
                    setTimeout(()=>{
                        board_.gameStarted = false;
                        localStorage.removeItem('gameSession');
                        localStorage.removeItem('n_players');
                        setTimeout(()=>{
                            window.location.reload();
                        },1000);
                    },1500);

                });

                this.$sigcon.on("DiceRolled",function(dice){
                    board_.resetDiceRoll(false);

                    board_.players.forEach((player)=>{
                        if(player.UserId == dice.CurrentPlayer){
                            board_.currentPlayer = player;
                            return;
                        }
                    });
                    board_.currentDiceRoll = dice.DiceRoll;
                    board_.animatePlayerDiceRoll();
                });

                this.$sigcon.on("PieceMoved",function(move){
                    board_.players.forEach((player)=>{
                        if(player.UserId == move.UserId){
                            board_.currentPlayer = player;
                            return;
                        }
                    });
                    board_.die_used = move.MovedToken.TokenState;

                    if(!board_.die_used){
                        board_.all_die_used = [];
                    }else{
                        board_.all_die_used.push(1);
                    }
                    board_.pieceMoved = true;
                    board_.pieceToMove = move;                 
                });
                this.$sigcon.on("PieceKilled",function(move){    
                    board_.pieceKilled = true;
                    board_.pieceToMove = move;
                    board_.killWalkAction();
                });
        },
        unmounted(){
            if(window.rtcstream){
                window.rtcstream.getTracks().forEach(track => track.stop());
                window.rtcstream = null;

                const keys = Object.keys(Peers);

                keys.forEach(key => {
                    document.getElementById(key).remove();
                })
            }
        },
        methods: {
            closeWinner(){
                this.gameComplete = false;
            },
            toastTap(){
                this.$toast.success("touch has been imported");
            },
            openSpectators(){
                this.$refs['option-select-sound'].click();

                this.showSpectators = true;
            },  
            closeSpectators(){
                this.$refs['option-select-sound'].click();

                this.showSpectators = false;
            },
            openShowPlayers(){
                this.$refs['option-select-sound'].click();

                this.showAllPlayers = true;
            },
            closeShowPlayers(){
                this.$refs['option-select-sound'].click();

                this.showAllPlayers = false;
            },
           
            scrollToLastMessage() {
                const el = this.$root.$refs.last_message;
                if (el) {
                  el.scrollIntoView({behavior: 'smooth'});
                }
            },
            openGroupChat(){
                this.$refs['option-select-sound'].click();
                if(this.players.length > 1){
                    this.showGroupChat = true;
                }else{
                    this.$toast.show("Are you Done?!...STOP IT!!!!!!!",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            closeGroupChat(){
                this.showGroupChat = false;
            },
            showTurn(player_){
                if(player_.PlayerTurn)
                    return 'cur-piece';
                else
                    return '';
            },
            pathBlocked(path){
                let blocked = '';
                if(this.gameState && this.gameState.GameRule){
                    if(this.gameState.GameRule.BlockGame && Object.keys(this.blocked_paths).length){
                        if(path in this.blocked_paths){
                            if(this.blocked_paths[path].Pieces.length > 1)
                                blocked = 'blocked';
                        }
                    } 
                }
                return blocked;            
            },
            calculateMove(piece,option,dieRoll1, dieRoll2,test_move){
                let total_moves = 0;
                if(typeof dieRoll1 == "undefined")
                    dieRoll1 = this.dieRoll1 ? this.dieRoll1 : 0;
                
                if(typeof dieRoll2 == "undefined")
                    dieRoll2 = this.dieRoll2 ? this.dieRoll2 : 0;

                if(!test_move){
                    if(this.die_used){
                        if(this.die_used == 2)
                            dieRoll1 = 0;   
                        else
                            dieRoll2 = 0;
                    }
                }

                switch(option){
                    case 1:
                        total_moves = parseInt(piece.Position) + parseInt(dieRoll1) + parseInt(dieRoll2);
                        break;
                    case 2:
                        total_moves = parseInt(piece.Position) + parseInt(dieRoll1);
                        break;
                    case 3:
                        total_moves = parseInt(piece.Position) + parseInt(dieRoll2);
                        break;
                    default:
                        break;
                }

                if(this.gameState.GameRule.BlockGame && Object.keys(this.blocked_paths).length)
                    total_moves = this.test_for_blocked_paths(piece,total_moves);

                return total_moves;
            },
            remove_piece_from_current_block(piece){
                let piece_block = piece.Position == 1 ? 0 : (piece.Position - 1);
                var current_block = this.$piece_direct_path[(piece.Player-1)][piece_block];
                
                if(current_block in this.blocked_paths){
                    var pieces = this.blocked_paths[current_block].Pieces;
                    if(pieces.length > 0){
                        if(pieces.length == 1){
                            delete this.blocked_paths[current_block];
                        }else{
                            this.blocked_paths[current_block].Pieces = this.blocked_paths[current_block].Pieces.filter(function (piece_){
                                return piece_.EntityId != piece.EntityId;
                            });
                        }
                    }
                }
            },
            test_for_blocked_paths(piece,total_moves){
                let current_pos = piece.Position == 1 ? 0 : (piece.Position - 1);
                if(current_pos >= 0){
                    for(var p = current_pos; p < total_moves; p++){
                       var path_ = this.$piece_direct_path[(piece.Player-1)][p];
                        if(path_ in this.blocked_paths){
                            var block_ = this.blocked_paths[path_];
                            var current_block = this.$piece_direct_path[(piece.Player-1)][current_pos];

                            if(block_.Player != piece.Player && block_.Pieces.length > 1){
                                if(path_ != current_block)
                                    total_moves = p ? p : 1;
                                
                                break;
                            }
                        }
                    }
                    return total_moves;
                }
                return 0;
            },
            pieceSelection(piece,mode){
                var player_has_pieces_active = this.playerHasActivePiece(this.player);
                if(mode == 'in'){
                    this.piece_selected = piece.EntityId;
                    if(player_has_pieces_active < 2){
                        this.showPieceProjection(piece,1);
                    }else{
                        if(this.dieRoll1 && this.dieRoll2 && !this.die_used){
                        
                        }else{
                            this.showPieceProjection(piece,1);
                        }
                    }
                }else{
                    if(mode == 'out'){
                        if(!piece.PieceMoveOptions){
                            this.piece_selected = null;
                        }else{
                            this.piece_selected = this.piece_selected;
                        } 
                        if(player_has_pieces_active < 2){
                            this.removePieceProjection(piece,1);
                        }else{
                            if(this.dieRoll1 && this.dieRoll2 && !this.die_used){

                            }else{
                                this.removePieceProjection(piece,1);
                            }
                        }
                    }
                }
            },
            showPieceProjection(piece,option){
                this.$refs["click-option-sound"].click();
                let path_ = null;
                let total_moves = this.calculateMove(piece,option) - 1;
                if(total_moves && total_moves <= 53)
                    path_ = this.$piece_direct_path[(piece.Player-1)][total_moves];

                if(path_){
                    if(total_moves == 52){
                        if(this.$refs[`home_${path_}`])
                            this.$refs[`home_${path_}`].classList.add("projection");
                    }
                        
                    if(this.$refs[`path_${path_}`])
                        this.$refs[`path_${path_}`].classList.add("projection");
                }                
                this.determinedBlock = path_;
            },
            removePieceProjection(piece,option,by_piece_position){
                let path_ = null;
                let total_moves = 0;
                if(!by_piece_position)
                    total_moves = this.calculateMove(piece,option) - 1;
                else
                    total_moves = (piece.Position -1) > 0 ? (piece.Position -1) : 1;

                if(total_moves <= 53 && total_moves)
                    path_ = this.$piece_direct_path[(piece.Player-1)][total_moves];

                if(by_piece_position)
                    this.determineBlockOwner(piece,total_moves);

                if(path_ && this.player.PlayerTurn){
                    if(total_moves == 52){
                        if(this.$refs[`home_${path_}`])
                            this.$refs[`home_${path_}`].classList.remove("projection");
                    }
                    
                    if(this.$refs[`path_${path_}`])
                        this.$refs[`path_${path_}`].classList.remove("projection");
                }

                this.determinedBlock = null;
            },
            killPlayerPiece(piece_block,piece){
                let board_ = this;
                let kill_block = JSON.parse(JSON.stringify(board_.blocked_paths[piece_block]));
                piece.PieceSelected = false;
                piece.PieceMoveOptions = false;
                piece.TotalKills = piece.TotalKills ? piece.TotalKills + 1 : 1; 
                if(piece.Position > 0)
                    piece.PieceDetained = true;
                
                board_.pieceKilled = true;
                board_.blocked_paths[piece_block] = {"Player":piece.Player,"Pieces":[piece]};
                board_.killPiece(kill_block,piece);
            },
            playerPassTurn(){
                let board_ = this;
                if(board_.player.PlayerTurn){
                    if(board_.dieRoll1 && board_.dieRoll2){
                        let posible_moves = 0;
                        posible_moves = board_.testPossibleMoves(board_.player,false,board_.die_used);

                        if(posible_moves){
                            board_.$toast.info("You do have possible moves, cant pass turn with moves to make.");
                        }else{
                            board_.gameThrow(false);
                        }
                    }else{
                        board_.$toast.info("Roll the dice first.");
                    }
                }else{
                    board_.$toast.warning("It is not your turn.");
                }
            },
            determineBlockOwner(piece,total_moves){
                let board_ = this;
                if(piece.Position == 1)
                    total_moves = 0;

                let piece_block = board_.$piece_direct_path[(piece.Player-1)][total_moves];
              
                if(!board_.blocked_paths.hasOwnProperty(piece_block)){
                    board_.blocked_paths[piece_block] = {"Player":piece.Player,"Pieces":[piece]};
                }else{
                    if(board_.blocked_paths[piece_block].Pieces.length > 0){
                        if(piece.Player == board_.blocked_paths[piece_block].Player){
                            let piece_on_path = false;
                            board_.blocked_paths[piece_block].Pieces.forEach((piece_) =>{
                                if(piece.EntityId == piece_.EntityId){
                                    piece_on_path = true;
                                    return;
                                }
                            });
                            if(!piece_on_path)
                                board_.blocked_paths[piece_block].Pieces.push(piece);
                        }else{   
                            board_.killPlayerPiece(piece_block,piece);
                        }
                    }else{
                        board_.blocked_paths[piece_block] = {"Player":piece.Player,"Pieces":[piece]};
                    }
                }
            
                setTimeout(()=>{
                    board_.playerAction(piece);
                },100);
            },
            closeWindow(window_){
                if(this.$root.$refs[window_]){
                    this.$root.$refs[window_].click();
                    this.closed_onliners = true;
                }else{
                    if(this.$parent.$refs[window_]){
                        this.$parent.$refs[window_].click();
                        this.closed_onliners = true;
                    }
                }       
            },
            cancelGame(){
                if(this.players.length > 1 && this.host)
                   this.confirmEndGameSession = true;
                else{
                    this.endGameSession();
                }
            },
            cancelClose(){
                this.confirmEndGameSession = false;
            },
            continueGame(){
                var board_ = this;
            },
            StayInGame(){
                var board_ = this;

            },
            leaveGame(){
                var board_ = this;

            },
            endGameSession(){
                if(this.confirmEndGameSession){
                    this.doGameCancel();
                }else{
                    if(this.host){
                        this.doGameCancel();
                    }else{
                        this.$toast.show("STOP IT!!!!!!!",{
                            duration: false,
                            dismissible: false
                        });
                    }
                }
            },
            doGameCancel(){
                this.gameSession = null;
                this.$toast.clear();
                this.$toast.show("<i class='fa fa-spin'></i> Game Session is closing.............");
                this.$sigcon.invoke("DeleteGameSession");
                localStorage.removeItem('n_players');
                localStorage.removeItem('gameSession');
            },
            useDefaultRules(){
                this.$refs['option-select-sound'].click();
                this.updateGameRules(true);
            },
            setTurnRule(rule){
                this.$refs['option-select-sound'].click();
                if(rule == 1){
                    this.rule_store["MinThrow"] = true;
                    this.rule_store["MaxThrow"] = false;
                }else{
                    this.rule_store["MinThrow"] = false;
                    this.rule_store["MaxThrow"] = true;
                }
            },
            setDiceRule(rule){
                this.$refs['option-select-sound'].click();
                if(rule == 1)
                    this.rule_store["DoubleDice"] = false;
                else
                    this.rule_store["DoubleDice"] = true;

            },
            setGateRule(rule){
                this.$refs['option-select-sound'].click();
                if(rule == 1)
                    this.rule_store["BlockGame"] = true;
                else
                    this.rule_store["BlockGame"] = false;

            },
            updateGameRules(default_){
                if(default_){
                    this.$sigcon.invoke("SetGameRule",{"DoubleDice":true});
                }else{
                    this.$sigcon.invoke("SetGameRule",this.rule_store);
                }
            },
            initDiceRoll(){
                if(this.player.PlayerTurn && !this.showDiceRoll && !this.showInitDiceRoll){
                    let roll = parseInt((Math.random() * 6)) + 1;
                    let board_ = this;
                    // board_.rollState = 1;
                    // board_.initRoll = roll;
                    let playerRoll = {"SessionId":board_.gameSession,"CurrentPlayer":board_.player.UserId,"DiceRoll":[roll]};
                    if(board_.player.PlayerTurn)
                        board_.$sigcon.invoke("RollDice",playerRoll);
                }else{
                    this.$toast.show("It is not your turn. STOP IT!!!!!!!",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            inGameDiceRoll(){
                let board_ = this;
                if(board_.player.PlayerTurn && !board_.showDiceRoll){
                    let player_has_active_piece = board_.playerHasActivePiece(board_.player);

                    if(!player_has_active_piece)
                        board_.throwsCounter += 1; 
                   
                    let roll1 = parseInt((Math.random() * 6)) + 1;
                    let roll2 = parseInt((Math.random() * 6)) + 1;
                   
                    if(board_.throwsCounter >= board_.minThrowsToSpawn && board_.gameState.GameRule.DoubleDice && !player_has_active_piece){
                        roll1 = 6;
                        roll2 = 6;
                        board_.throwsCounter = 0;
                        //change throws to spawn
                        board_.minThrowsToSpawn = parseInt((Math.random() * 7)) + 1;
                    }
                    let playerDiceRoll = [roll1];
                    if(board_.gameState.GameRule.DoubleDice)
                        playerDiceRoll.push(roll2);
                   
                    let playerRoll = {"SessionId":board_.gameSession,"CurrentPlayer":board_.player.UserId,"DiceRoll":playerDiceRoll};
                    if(board_.player.PlayerTurn)
                        board_.$sigcon.invoke("RollDice",playerRoll);
                }else{
                    board_.$toast.show("I see you are up to no good. STOP IT!!!!!!!",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            animatePlayerDiceRoll(){
                let board_ = this;
                board_.showDiceRoll = true;   
            },
            animateDiceRoll(el, done){
                let board_ = this;
                board_.$refs['dice-roll-sound'].click();
                var board_diceroll = anime.timeline({
                  targets: el,
                  duration: 1000,
                  easing: 'easeInSine',
                }).add({
                  translateX: [150, 250],
                  translateY: [150, 220],
                }).add({
                    translateX: [250, 10],
                    scale: [1, 1.2, 0.5]
                })
                board_diceroll.finished.then(this.endDiceRoll);
            },
            initAnimateDiceRoll(el, done){
                let board_ = this;
                var board_diceroll = anime.timeline({
                  targets: el,
                  duration: 1000,
                  easing: 'easeInSine',
                }).add({
                    translateY: [150, 220],
                    translateX: [250, 10],
                    scale: [1, 1.2, 0.5]
                });
                board_diceroll.finished.then(()=>{
                    if(board_.currentDiceRoll){
                        board_.currentPlayer.DiceRoll = board_.currentDiceRoll;
                        board_.dieRoll1 = board_.currentDiceRoll[0];
                        if(board_.currentDiceRoll.length > 1)
                            board_.dieRoll2 = board_.currentDiceRoll[1];
                    }
                    
                    if(board_.gameState.Round < 1){
                        board_.gameThrow(true);
                    }else{
                        if(!board_.die_used){
                            board_.invokeGameRules();
                        }else{
                            board_.determinePlayerNextMove();
                        }
                    }
                    board_.showPlayerTurn = board_.currentPlayer.PlayerPosition;
                });
            },
            endDiceRoll(){
                let board_ = this;
                if(board_.currentDiceRoll){
                    board_.currentPlayer.DiceRoll = board_.currentDiceRoll;
                    board_.dieRoll1 = board_.currentDiceRoll[0];
                    if(board_.currentDiceRoll.length > 1)
                        board_.dieRoll2 = board_.currentDiceRoll[1];
                }
                
                if(board_.$refs['dice-roll-sound-stop'])
                    board_.$refs['dice-roll-sound-stop'].click();

                setTimeout(()=>{
                    if(board_.gameState.Round < 1)
                        board_.gameThrow(true);
                    else
                        board_.invokeGameRules();

                    if(board_.currentPlayer)
                        board_.showPlayerTurn = board_.currentPlayer.PlayerPosition;
                },1500); 
            },
            playerHasActivePiece(player_){
                let active_piece = 0;
                player_.Pieces.forEach((piece)=>{
                    if(piece.Position > 0 && piece.Position < 53)
                        active_piece = active_piece + 1;
                }); 
                return active_piece;
            },
            playerPiecesHomed(player_){
                let active_piece_homed = 0;
                player_.Pieces.forEach((piece)=>{
                    if(piece.Position >= 53)
                        active_piece_homed += 1;
                }); 
                return active_piece_homed;
            },

            resetDiceRoll(update){
                let board_ = this;
                board_.offlinePlayers = [];
                board_.all_die_used = [];
                board_.die_used = 0;
                board_.dieRoll1 = null;
                board_.dieRoll2 = null;
                board_.rollState = null;
                board_.pieceToMove = null;
                board_.prevPlayer = null;
                board_.currentPlayer = null;
                board_.currentDiceRoll = null;
                board_.pieceMoved = false;
                board_.moveActivePiece = false; 
                board_.showInitDiceRoll = false;
                board_.pieceKilled = false;
                board_.showDiceRoll = false;
                board_.pass_turn = false; 
                board_.showPlayerTurn = false;
                board_.pieceAnimation = false;
                board_.diceRoll = false;
            },

            invokeGameRules(){
                let board_ = this;
                let test_rules = board_.testTurnRules();
                let active_piece = test_rules[0];
                let pass_turn = test_rules[1];
                let born_piece = test_rules[2];

                if(pass_turn){
                    board_.gameThrow(false);
                }else{
                    if(this.player.PlayerTurn){
                        if(active_piece){
                            if(born_piece)
                                board_.allowPieceBorn();
                            
                            board_.allowPieceMove();
                        }else{
                            board_.allowPieceBorn();
                        }
                    }else{
                        this.moveActivePiece = false;
                    }
                }
            },

            testPossibleMoves(player_,auto,die_used){
                let board_ = this;
                let possible_moves = 0;
                let pieces_that_can_move = [];
                let pieces_homed = [];
                let unborned_pieces = [];
                let can_spawn_piece = true;
                player_.Pieces.forEach((piece)=>{
                    if(piece.Position == 53)
                        pieces_homed.push(1);

                    if(piece.Position == 0)
                        unborned_pieces.push(1);

                    if(piece.Position > 0 && piece.Position < 53){
                        let piece_can_move = true;
                        let moves = board_.calculateMove(piece,1,board_.dieRoll1,board_.dieRoll2,true);
                        piece.PieceCanMove = false;
                        if(parseInt(piece.Position) == parseInt(moves))
                            piece_can_move = false;

                        if(parseInt(moves) > 53 || die_used)
                            piece_can_move = false;

                        if(piece_can_move){ 
                            possible_moves = possible_moves + 1;
                            piece.use = 1;
                            pieces_that_can_move.push(piece);
                            piece.PieceCanMove = true;
                            return;
                        }else{
                            moves = board_.calculateMove(piece,2,board_.dieRoll1,board_.dieRoll2,true);
                            piece_can_move = true;
                            if(parseInt(piece.Position) == parseInt(moves))
                                piece_can_move = false;

                            if(parseInt(moves) > 53 || die_used == 2)
                                piece_can_move = false;

                            if(piece_can_move){
                                possible_moves = possible_moves + 1;
                                piece.use = 2;
                                pieces_that_can_move.push(piece);
                                piece.PieceCanMove = true;
                                return;
                            }else{
                                moves = board_.calculateMove(piece,3,board_.dieRoll1,board_.dieRoll2,true);
                                piece_can_move = true;
                                if(parseInt(piece.Position) == parseInt(moves))
                                    piece_can_move = false;

                                if(parseInt(moves) > 53 || die_used == 3)
                                   piece_can_move = false;

                                if(piece_can_move){
                                    possible_moves = possible_moves + 1;
                                    piece.use = 3;
                                    pieces_that_can_move.push(piece);
                                    piece.PieceCanMove = true;
                                    return;
                                }
                            }

                        }

                    }

                }); 

                let any_six = board_.dieRoll1 == 6 || board_.dieRoll2 == 6 ? true : false;
                if(any_six && possible_moves == 0){
                  if(unborned_pieces.length && !board_.playerGateBlocked(player_)){
                       possible_moves = possible_moves + 1;
                    }
                }
                if(pieces_that_can_move.length == 1 && possible_moves && auto)
                    board_.actionPossibleMoves(player_,possible_moves,pieces_homed,unborned_pieces,pieces_that_can_move[0],any_six);

                return possible_moves;
            },
            actionPossibleMoves(player_,posible_moves,pieces_homed,unborned_pieces,piece_to_move,any_six){
                let board_ = this;
                let piece = piece_to_move;
                if(any_six){
                    if(unborned_pieces.length > 0 && board_.playerGateBlocked(player_)){
                        board_.setBoardPassVariables(piece);
                        board_.actionPieceMove(piece, board_.dieRoll1,board_.dieRoll2,piece.use);
                    }else{
                         if(pieces_homed.length == 3){
                            board_.setBoardPassVariables(piece);
                            board_.actionPieceMove(piece, board_.dieRoll1,board_.dieRoll2,piece.use);
                        }
                    }
                }else{
                    board_.setBoardPassVariables(piece);
                    board_.actionPieceMove(piece, board_.dieRoll1,board_.dieRoll2,piece.use);
                }
            },
            setBoardPassVariables(piece){
                let board_ = this;
                if(piece.use == 2)
                    board_.die_used = 3;
                else if(piece.use == 3)
                    board_.die_used = 2;

                board_.all_die_used = [1];
            },
            playerGateBlocked(player){
                let board_ = this;
                let player_gate = board_.$piece_direct_path[(player.PlayerPosition -1)][0];
                if(player_gate in board_.blocked_paths){
                    if(board_.blocked_paths[player_gate].Player != player.PlayerPosition && board_.blocked_paths[player_gate].Pieces.length > 1)
                        return true;
                }
                return false;
            },
            testTurnRules(){
                let passTurn = false;
                let born_piece = false;
                let board_ = this;
                let player_dice_roll = board_.currentPlayer.DiceRoll;
                let has_active_piece = board_.playerHasActivePiece(board_.currentPlayer);
                let posible_moves = 1;
                if(has_active_piece)
                    posible_moves = board_.testPossibleMoves(board_.currentPlayer,true);

                board_.dieRoll1 = player_dice_roll[0];
                if(player_dice_roll.length > 1){
                    board_.dieRoll2 = player_dice_roll[1];
                    board_.any_six = board_.dieRoll1 == 6 || board_.dieRoll2 == 6 ? true : false;
                    board_.double_six = board_.dieRoll1 == 6 && board_.dieRoll2 == 6 ? true : false;
                    board_.has_active_piece = has_active_piece;
                    board_.posible_moves = posible_moves;
            
                    if(board_.double_six)
                        born_piece = true;

                    if(has_active_piece && board_.any_six)
                        born_piece = true;

                    if(!board_.double_six){
                        if(!has_active_piece){
                            passTurn = true;
                        }else{
                            if(!board_.any_six && !has_active_piece)
                                passTurn = true;
                        }    
                    }

                    if(!posible_moves)
                        passTurn = true;

                    if(!has_active_piece && board_.any_six && board_.playerGateBlocked(board_.currentPlayer))
                        passTurn = true;

                    if(board_.any_six && board_.playerGateBlocked(board_.currentPlayer))
                        born_piece = false;

                    board_.pass_turn = passTurn;
                    
                }else{
                    board_.has_active_piece = has_active_piece;
                    board_.posible_moves = posible_moves;
                    board_.any_six = board_.dieRoll1 == 6 ? true : false;

                    if(board_.any_six)
                        born_piece = true;

                    if(!board_.any_six && !has_active_piece)
                        passTurn = true;
                    
                    if(!posible_moves)
                        passTurn = true;
                    
                    board_.pass_turn = passTurn;
                }
                return [has_active_piece, passTurn, born_piece];
            },
            allowPieceMove(){
                let board_ = this;
                if(board_.currentPlayer){
                    if(board_.player.UserId === board_.currentPlayer.UserId && board_.player.PlayerTurn)
                        board_.moveActivePiece = true;
                }
            },
            addToActivePieces(piece_){
                let board_ = this;
                board_.pieceAnimation = true;
                if(board_.active_pieces.length < 1){
                    piece_["Player"] = board_.currentPlayer.PlayerPosition;
                    board_.active_pieces.push(piece_);
                }else{
                    let already_born = false;
                    board_.active_pieces.forEach(piece=>{
                        if(piece.EntityId == piece_.EntityId){
                            already_born = true;
                            return;
                        }
                    });
                    if(!already_born){
                        piece_["Player"] = board_.currentPlayer.PlayerPosition;
                        board_.active_pieces.push(piece_);
                    }
                }
            },
            getPlayerPiece(){
                let board_ = this;
                let piece_ = null;
                board_.currentPlayer.Pieces.forEach((piece)=>{
                    if(piece.EntityId == board_.pieceToMove.MovedToken.EntityId){
                       piece_ = piece;
                       return;
                    }
                }); 
                return piece_;
            },
            getPlayerKilledPiece(){
                let board_ = this;
                let piece_ = null;
                let piece_found = false;
                board_.players.forEach((player)=>{
                    if(player.UserId == board_.pieceToMove.UserId){
                        player.Pieces.forEach((piece)=>{
                            if(piece.EntityId == board_.pieceToMove.MovedToken.EntityId){
                               piece_ = piece;
                               piece_found = true;
                               return;
                            }
                        });
                    }
                    if(piece_found)
                        return;
                });
                return piece_;
            },
            killWalkAction(){
                let board_ = this;
                let walk_start = false;
                if(board_.pieceKilled){
                    board_.allowPlay = false;
                    if(board_.pieceToMove){
                        let piece_ =  board_.getPlayerKilledPiece(board_.pieceToMove);
                        if(piece_){
                            let piece_walk_interval = setInterval(()=>{
                                if(piece_.Position > 0){
                                    if(!walk_start && Object.keys(board_.blocked_paths).length)
                                        board_.remove_piece_from_current_block(piece_);

                                    piece_.Position = piece_.Position - 1;
                                    walk_start = true;
                                }else{
                                    board_.pieceKilled = false;
                                    board_.allowPlay = true;
                                    clearInterval(piece_walk_interval);
                                     if(board_.player.PlayerTurn){
                                        //then determine how to proceed
                                        setTimeout(()=>{
                                            board_.determinePlayerNextMove();
                                        },300);
                                    }
                                }
                            },70);
                        }
                    }
                }
                        board_.allowPlay = true;
                
            },

            pieceWalkAction(piece_){
                let board_ = this;
                let walk_start = false;
                board_.allowPlay = false;
                let piece_walk_interval = setInterval(()=>{
                    if(board_.pieceToMove){
                        if(piece_.Position < board_.pieceToMove.MovedToken.Position){
                            if(board_.$refs["chip-move-sound-stop"])
                                board_.$refs["chip-move-sound-stop"].click();
                            
                            if(board_.$refs["chip-move-sound"])
                                board_.$refs["chip-move-sound"].click();
                            
                            if(!walk_start && Object.keys(board_.blocked_paths).length)
                                board_.remove_piece_from_current_block(piece_);

                            piece_.Position = piece_.Position + 1;
                            walk_start = true;
                        }else{
                            clearInterval(piece_walk_interval);
                            board_.allowPlay = true;

                            board_.pieceAnimation = false;
                            if(piece_.Position >= 53){
                                if(board_.$refs["home-move-sound"])
                                    board_.$refs["home-move-sound"].click();
                                
                                board_.homed_pieces[piece_.Player].push(piece_);
                            }

                            if(board_.playerPiecesHomed(board_.currentPlayer) == 4){
                                board_.gameWon(board_.currentPlayer);
                            }else{
                                board_.removePieceProjection(piece_,1,true);

                                if(!board_.pieceKilled){
                                    if(board_.player.PlayerTurn){
                                        //then determine how to proceed
                                        setTimeout(()=>{
                                            board_.determinePlayerNextMove(piece_);
                                        },300);
                                    }
                                }
                            }
                        }
                    }else{
                        board_.allowPlay = true;
                        clearInterval(piece_walk_interval);
                    }
                },350); 
            },
           
            animatePieceMove(){
                let board_ = this;
                board_.pieceAnimation = true;
                if(board_.currentPlayer.PlayerTurn && board_.currentPlayer.UserId == board_.pieceToMove.UserId){
                    let piece_ = board_.getPlayerPiece();
               
                    if(piece_){
                        board_.addToActivePieces(piece_);
                        if(board_.pieceToMove.MovedToken.Position > 0){
                            //animate piece move
                            board_.pieceWalkAction(piece_);
                        }
                    }
                }
            },
            playerLeaveGame(){
                var board_ = this;
                board_.gameComplete = true;
                board_.invoke("LeaveGameSession");
            },
            gameWon(winner){
                var board_ = this;
                if(winner){
                    board_.gameWinner = winner;
                    board_.gameComplete = true;
                    board_.invoke("GameEnd",winner);
                }else{
                    board_.stopWithTheFoolery();
                }
            },
            gameThrow(pre_game){
                let board_ = this;
                if(board_.currentPlayer && board_.currentPlayer.DiceRoll){
                    let playerDiceRoll = [board_.currentPlayer.DiceRoll[0]];
                    if(board_.currentPlayer.DiceRoll.length > 1)
                        playerDiceRoll.push(board_.currentPlayer.DiceRoll[1]);

                    let playerRoll = {"SessionId":board_.gameSession,"CurrentPlayer":board_.currentPlayer.UserId,"DiceRoll":playerDiceRoll};
                    
                    board_.pass_turn = true;
                    if(board_.player.PlayerTurn){
                        board_.resetDiceRoll(true);

                        if(pre_game)
                            board_.$sigcon.invoke("PreGameThrow",playerRoll); 
                        else
                            board_.$sigcon.invoke("GameThrow",playerRoll); 
                        
                        board_.resetDiceRoll(false);
                    }

                }
            },
            determinePlayerNextMove(piece_){
                //possible moves rule action //:if double dice //:if active piece //0 allow piece born //0- :if piece born from die roll //0+ :set the die_value null to remove it from movePlayerPiece options //1 move active piece to total rolled i.e(die roll 1 + 2) //2 move active piece to die roll 1 //3 move active piece to die roll 2 //- after both die values are used //:if any six was thrown keep player turn //:else passTurn gamethrow // board_.$sigcon.invoke("GameThrow",playerRoll); 
                let board_ = this;
                board_.piece_selected = null;
                board_.pieceMoved = false;
      
                if(board_.all_die_used.length >= board_.currentPlayer.DiceRoll.length){
                    board_.pieceMoved = false;
                    board_.all_die_used = [];
                    board_.die_used = 0;
                    board_.gameThrow(false);
                }else{
                    if(board_.has_active_piece){
                        
                        switch(board_.die_used){
                            case 1:
                                board_.pieceMoved = false;
                                board_.gameThrow(false);
                                break;
                            case 2:
                                board_.testPossibleMoves(board_.currentPlayer,true,3);
                                if(board_.dieRoll2 == 6)
                                    board_.allowPieceBorn();
                                break;
                            case 3:
                                board_.testPossibleMoves(board_.currentPlayer,true,2);
                                if(board_.dieRoll1 == 6)
                                    board_.allowPieceBorn();
                                break;
                            default:
                                break;
                        }
                        board_.allowPieceMove();
                        
                    }else{
                        board_.gameThrow(false);
                    }
                }
            
            },
            allowPieceBorn(){
                if(this.player.PlayerTurn){
                    this.player.PlayerYardPosition = 1;
                }
            },
            resetPlayerPieceAndSelections(player){
                let board_ = this;
                let block_ = board_.determinedBlock;
                if(block_){
                    board_.$refs[block_].classList.remove("projection");
                    board_.determinedBlock = null;
                }
                
                player.Pieces.forEach((piece)=>{
                    piece.PieceSelected = false;
                    piece.PieceMoveOptions = false;
                });
            },
            bornPlayerPiece(piece_){
                let piece = JSON.parse(JSON.stringify(piece_));
                if(this.player.PlayerTurn && this.player.PlayerYardPosition > 0){
                    this.resetPlayerPieceAndSelections(this.player);
                    let piece_valid = false;
                    this.player.Pieces.forEach((tpiece_)=>{
                        if(tpiece_.EntityId == piece.EntityId){
                            piece.Position = 1;
                            piece.TotalMoves = 1; 
                            piece_valid = true;
                        }
                    });
                    this.player.PlayerYardPosition = 0;
                    if(piece_valid){
                        if(this.has_active_piece){
                            if(this.dieRoll1 == 6)
                                piece.TokenState = 2;
                            else if(this.dieRoll2 == 6)
                                piece.TokenState = 3;

                        }else{
                            piece.TokenState = 0;
                        }
                        this.makePieceMove(piece);
                    }
                }else{
                    this.$toast.show("Wow you are something else. If you are actually in this WAIT YOUR TURN else Whatever you're trying to do, STOP IT!!!!!!!",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            enablePieceOption(piece){
                if(!piece.PieceMoveOptions){
                    this.piece_selected = piece.EntityId;
                    piece.PieceSelected = true;
                    piece.PieceMoveOptions = true;
                }else{
                    this.piece_selected = null;
                    piece.PieceSelected = false;
                    piece.PieceMoveOptions = false;
                }
            },
            resetSeletedPieces(piece){
                this.player.Pieces.forEach((piece_) =>{
                    if(piece_.EntityId != piece.EntityId){
                        piece_.PieceSelected = false;
                        piece_.PieceMoveOptions = false;
                    }
                });
            },
            getPieceOptions(piece){
                if(this.player.PlayerTurn && piece.Position > 0 && this.moveActivePiece){
                    let piece_cant_play = false;
                    if(piece.PieceDetained)
                        piece_cant_play = true;
                    //close all open pieceOptions dialog
                    if(!piece_cant_play){
                        this.resetSeletedPieces(piece);
                        if(this.dieRoll1 && this.dieRoll2 && !this.die_used && this.playerHasActivePiece(this.player) > 1){
                            this.enablePieceOption(piece);
                        }else{ 
                            if(piece.PieceSelected){
                                piece.PieceSelected = false;
                                this.movePlayerPiece(piece,1);
                            }else{
                                piece.PieceSelected = true;
                            }                            
                        }
                    }else{
                        this.$toast.error("this piece was involved in a murder, it is detained until your next turn.",{
                            duration: false,
                            dismissible: true
                        });
                    }
                }else{
                    this.$toast.show("Ah ah ah....You are 'high lary us'. STOP IT!!!!!!!",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            movePlayerPiece(piece,option){
                if(this.player.PlayerTurn && piece.Position > 0 && this.moveActivePiece){
                    let dieRoll1 = this.dieRoll1 ? this.dieRoll1 : 0;
                    let dieRoll2 = this.dieRoll2 ? this.dieRoll2 : 0;
                    if(option){
                        if(this.die_used){
                            if(this.die_used == 2){
                                dieRoll1 = 0;
                            }else{
                                if(this.die_used == 3){
                                    dieRoll2 = 0;
                                }
                            }
                            option = 1;
                        }
                        this.actionPieceMove(piece, dieRoll1, dieRoll2, option);
                    }else{
                        this.$toast.show("No valid moves to make. Roll dice again.",{
                            duration: false,
                            dismissible: false
                        });
                    }
                }else{
                    this.$toast.show("Ah ah ah....You are 'high lary us'. STOP IT!!!!!!!",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            actionPieceMove(piece_, dieRoll1, dieRoll2, option){
                //clone pice so it doesnt update orinal piece b4 piceMoved is invoked
                let piece = JSON.parse(JSON.stringify(piece_));
                let total_moves = 0;
                if(option){
                    total_moves = this.calculateMove(piece_,option,dieRoll1, dieRoll2);
                    piece.TotalMoves = total_moves - piece.Position;
                    piece.Position = total_moves;

                    piece_.PieceMoveOptions = false;
                    piece.PieceMoveOptions = false;
                    let remaining_moves = 53 - piece_.Position;
                    let can_move_to_home = piece_.Position + piece.TotalMoves <= 53 ? true : false;
                    if(piece.TotalMoves > 0){
                        if(can_move_to_home){
                            piece.TokenState = option;
                            this.makePieceMove(piece);
                        }else{``
                            this.$toast.show(`You need exactly ${remaining_moves} to home this piece.`);
                        }
                    }
                }else{
                    this.$toast.show("Follow the correct sequence to trigger this method.",{
                        duration: false,
                        dismissible: false
                    });
                }
            },
            killPiece(piece_,killer_token){
                let board_ = this;
                if(board_.$refs["kill-piece-sound"])
                    board_.$refs["kill-piece-sound"].click();

                let piece = JSON.parse(JSON.stringify(piece_.Pieces[0]));
                let userID = board_.players[(piece_.Player - 1)].UserId;
                let dieUsed = 0;
                
                piece.TotalMoves = piece.Position;
                piece.Position = 0;
                piece.DeathCount = piece.DeathCount + 1;

                let playerAction = {"UserId":userID,"SessionId":board_.gameSession,"Moves":piece.TotalMoves,"PauseGame":false,"MovedToken":piece,"DieUsed":[dieUsed],"BlockedPaths":JSON.parse(JSON.stringify(board_.blocked_paths)),"KilledBy":board_.currentPlayer.UserId,"KillerToken":killer_token};
                if(board_.player.PlayerTurn)
                    board_.$sigcon.invoke("KillPiece",playerAction);
            },
            makePieceMove(piece_){
                let board_ = this;
                let piece = piece_;
                let userID = board_.player.UserId;
                let dieUsed = board_.die_used;
                if(!dieUsed)
                    dieUsed = 0;

                let playerAction = {"UserId":userID,"SessionId":board_.gameSession,"Moves":piece.TotalMoves,"PauseGame":false,"MovedToken":piece,"DieUsed":[dieUsed],"BlockedPaths":JSON.parse(JSON.stringify(board_.blocked_paths))};

                if(board_.player.PlayerTurn)
                    board_.$sigcon.invoke("MovePiece",playerAction);
            },
            playerAction(piece,dicerolled){
                let board_ = this;
                if(board_.die_used){
                    let playerAction = {"UserId":board_.player.UserId,"SessionId":board_.gameSession,"Moves":piece.TotalMoves,"PauseGame":false,"MovedToken":piece,"DieUsed":[dicerolled ? 4 : board_.die_used ],"BlockedPaths":JSON.parse(JSON.stringify(board_.blocked_paths))};
                                
                    if(this.player.PlayerTurn)
                        this.$sigcon.invoke("PlayerAction",playerAction);
                }
            },
            updateGameState(init){
                let board_ = this;
                let gameState = board_.gameState;
                
                if(gameState){
                    console.log(gameState);
                   
                    if(board_.pieceMoved){
                        board_.pieceMoved = false;
                        board_.animatePieceMove();
                    }else{
                        if(!board_.gameSession){
                            board_.gameSession = gameState.SessionId;
                            localStorage.setItem('gameSession', board_.gameSession);
                        }
                        board_.resetDiceRoll(true);
                        board_.reinitGlobalVariables(init,gameState);
                    }
                }else{
                    if(board_.n_players || board_.gameSession){
                        localStorage.removeItem('n_players');
                        localStorage.removeItem('gameSession');
                        window.location.reload();
                    }
                }
            },
            setPreviousPlayerAndGameHost(init,gameState){
                let board_ = this;
                let prevPlayer = board_.prevPlayer;
                if(board_.prevPlayer && board_.currentPlayer){
                    if(board_.prevPlayer.UserId == board_.currentPlayer.UserId){
                        switch(board_.currentPlayer.PlayerPosition){
                            case 1:
                                prevPlayer = board_.players[(board_.players.length - 1)];
                                break;
                            case 2:
                                prevPlayer = board_.players[0];
                                break;
                            case 3:
                                prevPlayer = board_.players[1];
                                break;
                            case 4:
                                prevPlayer = board_.players[2];
                            default:
                            break;
                        }
                        board_.prevPlayer = prevPlayer;
                    }
                }

                if(board_.players.length == 1)
                    board_.host = true;
                else{   
                    if(board_.player.PlayerPosition == 1)
                        board_.host = true;
                }

                board_.resumeInteruptedThrow(init,gameState);
            },
            initRoundAndSetGameRule(init,gameState){
                let board_ = this;
                if(board_.players.length >= 1){
                    if(board_.players.length == parseInt(gameState.NumOfPlayers)){
                        if(!board_.closed_onliners){
                            if(!init)
                                board_.$toast.info("All players are in.....Starting game...");
                            else
                                board_.$toast.info("Resuming game......");
                        }
                       
                        board_.gameInProgress = true;
                        board_.gameStarted = true;
                        localStorage.setItem('playStarted',true);

                        if(gameState.Round == 0)
                            board_.initTurnSelection = true;
                        else
                            board_.initTurnSelection = false;

                        if(gameState.GameRule){
                            board_.gameRules = gameState.GameRule;

                            if(gameState.GameRule.MinThrow)
                                board_.TurnWinnerRule = 1;
                            if(gameState.GameRule.MaxThrow)
                                board_.TurnWinnerRule = 2;
                        }

                        if(!board_.closed_onliners){
                            board_.closed_onliners = true;
                            board_.closeWindow("close_onliners");
                        }

                    }else{
                        board_.gameStarted = true;
                        board_.$toast.info("Waiting for all players to join...");
                    }
                }

                board_.setPreviousPlayerAndGameHost(init,gameState);
            },
            verifyOnlinePlayers(init,gameState){
                let board_ = this;
                board_.initRoundAndSetGameRule(init,gameState);
            },
            buildBoardPlayers(init,gameState){
                let board_ = this;
                gameState.Players.forEach(function(player){
                    if(player.Pieces){
                            player.Pieces.forEach(piece=>{
                                if(piece.Position > 0){
                                    piece["Player"] = player.PlayerPosition;
                                    piece.PieceMoveOptions = false;
                                    //this will cause double six born rule to activate
                                    if(piece.Position < 53){
                                        board_.active_pieces.push(piece);
                                    }
                                    else{
                                        board_.homed_pieces[player.PlayerPosition].push(piece);
                                        if(board_.homed_pieces[player.PlayerPosition].length == 4){
                                            board_.gameComplete = true;
                                            board_.gameWinner = player;                
                                        }
                                    }
                                }   
                        });
                    }

                    if(player.PlayerTurn)
                        board_.currentPlayer = player;

                    if(player.UserId == board_.$user.UserId)
                        board_.player = player; 

                    if(gameState.LastPlayerStateChangedId && player.UserId == gameState.LastPlayerStateChangedId)
                        board_.prevPlayer = player;

                    if(parseInt(player.PlayerPosition) <= 4)
                        board_.players.push(player);
                    else
                        board_.spectators.push(player);

                });    

                setTimeout(()=>{
                    if(board_.gameWinner){
                        console.log(board_.gameWinner);
                        if(board_.gameWinner.UserId == board_.player.UserId)
                            board_.gameWon(board_.gameWinner);
                    }
                }, 1000);
                board_.verifyOnlinePlayers(init,gameState);
            },
            reinitGlobalVariables(init,gameState){
                //populate group message object on refresh
                let board_ = this;
                board_.resetDiceRoll(true);
                board_.active_pieces = [];
                board_.homed_pieces = {1:[],2:[],3:[],4:[]};
                board_.players = [];
                board_.spectators = [];
          
                if(Object.keys(board_.group_messages).length < 1 && gameState.Messages){
                    gameState.Messages.forEach((message)=>{
                        board_.group_messages[message.MessageId] = message;
                    });
                }
                if(gameState.BlockedPaths && Object.keys(gameState.BlockedPaths).length){
                    board_.blocked_paths = gameState.BlockedPaths;
                }

                if(gameState.Players){
                    board_.buildBoardPlayers(init,gameState);
                }else{
                    board_.$toast.info("Game will commence as soon as all players have joined.");
                }      
            },     
            resumeInteruptedThrow(init,gameState){
                let board_ = this;
                
                if(init && !gameState.IsPreGame){
                    if(board_.currentPlayer){
                        if(board_.currentPlayer.Thrown){
                            if(board_.currentPlayer.DieUsed && board_.currentPlayer.DieUsed.length){
                                let dieUsed = board_.currentPlayer.DieUsed[0];
                                if(dieUsed > 1){
                                    board_.die_used = dieUsed;
                                    board_.all_die_used = [1];
                                    if(board_.currentPlayer.DiceRoll){
                                        board_.currentDiceRoll = board_.currentPlayer.DiceRoll;
                                        board_.dieRoll1 = board_.currentPlayer.DiceRoll[0];
                                        if(board_.currentPlayer.DiceRoll.length > 1)
                                            board_.dieRoll2 = board_.currentPlayer.DiceRoll[1];
                                    }
                                    if(board_.active_pieces.length)
                                        board_.has_active_piece = true;
                                
                                    board_.showInitDiceRoll = true;
                                }
                            }else{
                                if(board_.currentPlayer.DiceRoll){
                                    board_.currentDiceRoll = board_.currentPlayer.DiceRoll;
                                    board_.dieRoll1 = board_.currentPlayer.DiceRoll[0];
                                    if(board_.currentDiceRoll.length > 1)
                                        board_.dieRoll2 = board_.currentPlayer.DiceRoll[1];
                                }
                                board_.showInitDiceRoll = true;
                            }
                        }
                    }
                }
            },
            createAudioCreate(){
                navigator.mediaDevices.getUserMedia({audio: {echoCancellation: true},video: false})
                .then(stream => {
                    window.rtcstream = stream;
                    window.rtcstream.getAudioTracks()[0].enabled = false;
                    var event = new CustomEvent('mediaDeviceReady',{detail: true});
                    window.dispatchEvent(event);
                    // console.log("Custom event fired: mediaDeviceReady.");
                })
                .catch(() => {
                    // this.$toast.warning("This feature allows you to use voice within the game, if disabled you can only use the text chat.",{duration: false,dismissible:true});
                });
            },
            toggleMic(){
                this.audioMuted = !this.audioMuted;
                window.rtcstream.getAudioTracks()[0].enabled = !window.rtcstream.getAudioTracks()[0].enabled;
            },
            toggleAudio: function(){
                const keys = Object.keys(Peers);

                keys.forEach(key => {
                    let audio = document.getElementById(key);

                    if(audio.volume !== 0){
                        audio.volume = 0;
                    }else{
                        audio.volume = 1;
                    }
                })
            },
            stopWithTheFoolery(){
                board_.$toast.warning("Stop with the foolery.");
            },
            Logout(e){
                e.preventDefault();
                let board_ = this;
                if(this.$user.IsAuthenticated){
                    this.$http.get('/user/Logout')
                     .then(() => {
                            board_.$toast.success("Logout in progress......");
                            localStorage.removeItem('user');
                            localStorage.removeItem('jwt');
                            localStorage.removeItem('token_expiry');
                            localStorage.removeItem('gameSession');
                            localStorage.removeItem('playStarted');
                            localStorage.removeItem('n_players');
                            board_.$user.IsAuthenticated = false;
                        setTimeout(function(){
                            window.location.reload();
                        },500);
                     })
                }
            }
        },
        
    };
</script>

<style scoped>

.home_1 {
    left: 0;
    top: 0;
}

.home_2 {
    top: 0;
    right: 0;
}

.home_3 {
    right: 0;
    bottom: 0;
}

.home_4 {
    left: 0;
    bottom: 0;
}

.player-box {
    width: 50%;
    height: 50%;
    position: absolute;
    display: grid;
    grid-template-columns: 24% 42% 14%;
    grid-template-rows: 35% 31% 14% 40%;
    grid-auto-flow: row;
    z-index: 10;
}

.player-start-box {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  padding: 5%;
}

.first-trail {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: repeat(6,auto);
  grid-template-rows: auto;
  grid-auto-flow: row;
}

.second-trail {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;

  display: grid;
  grid-template-columns: repeat(1,auto);
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: row;
}

.player-home-box {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 6;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(1, auto); 
  grid-auto-flow: row;
}

.rolling {
    position: absolute;
    /*width: 500px;*/
    height: 500px;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(.8);
    transform: translateX(-50%) scale(.8);
    border-radius: 5px;
    /*z-index: 28;*/
}

.home-piece {
    position: absolute;
    width: 50%;
    top: 15%;
    transform: translateX(-48%) scale(.8);
    z-index: 30;
    padding: 5%;
}

.pie {
    border-radius: 50%!important;
    line-height: 4px;
    padding: 3px;
}

.piece-on-path {
    position: absolute;
    -webkit-transform: translateX(-50%) scale(.8);
    transform: translateX(-50%) scale(.8);
}

.open-players-dialog {
    cursor: pointer;
    color: #F80058;
}

.open-players-dialog:hover {
    color: #fff;
}

.block {
    border: 1px solid #000;
}

.p-1 {
    padding: 3px;
}

.dice {
    font-size: 50px;
}

.dice-enter-active, .dice-leave-active {
  transition: opacity 0.1s ease-out;
}

.dice-enter, .dice-leave-to {
  opacity: 0;
}

.piece-move-enter-active, .piece-move-leave-active {
  transition: all 0.5s ease-in;
}

.piece-move-enter, .piece-move-leave-to {
  opacity: 1;
}

#dice-roller {
    font-size: 50px;
    cursor: pointer;
}

#dice-roller:hover {
    color: #03FF2D;
}

.player-name {
    max-width: 50%;
    font-size: 9px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.home-name {
    position: absolute;
    display:  block;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 0;
}

.home_1 .home-name {
    top:  -50%;
}

.home_2 .home-name {
    left: -50%;
    transform: rotate(-90deg);
}

.home_3 .home-name {
    top:  -50%;
    transform: rotate(180deg);
}

.home_4 .home-name {
    left: -50%;
    transform: rotate(90deg);
}

.leave-game {
    position: fixed;
    top: 3%;
    right: 7%;
    color: #BDF60E;
    border-radius: 50%;
    cursor: pointer;
}

.leave-game:hover {
    color: #FB1717 !important;
    /*box-shadow: 0px 0px 11px #FEFB08;*/
}

.no-turn {
    /*background: #999;*/
}

.turn {
    background: #fc3;
    transition: all 1s ease-in;
}

.middle-layout {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
}

.clickable {
    outline: none!important;
    border:none!important; 
    border-radius: 50%!important;
    border-color: transparent;
    color: #fc3!important;
}

.clickable:hover {
    color: #2BC6F2!important;
}

.clickable:active, .clickable:focus{
    outline: none!important;
    border:none!important; 
}

.gamecontrols {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: .51%;
    z-index: 15;
}

.gamecontrols ul {
    margin:  0px;
}

.round-init {
    position: absolute;
    width: 140px;
    min-width: 130px;
    max-height: 80vh;
    background: #fff;
    padding: .5%;
    left: 1%;
    bottom: 10%;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    z-index: 20;
    box-shadow: 0px 5px 8px rgba(34, 35, 58, 0.5);
}

.game-rules {
    display: block;
    width: 100%;
    overflow: auto;
}

.rules {
    font-size: 9px;
    font-weight: bold;
    color: #13d9e2;
}
 
.rules em span {
    color: blue;
 }

#confirm-rules:hover {
    background: #09F546!important;
    border-color: #09F546;
}

.penalty {
    color:  #FA1B1B !important;
}

.selected-piece {
    color: #44BAFD !important;
}

.show-turn {
    position: absolute;
    height: 50px;
    width: 15%;
    left: 1%;
    bottom: 20%;
}

.show-player-turn {
    width: 100%;
    left: 0;
}

.show-player-turn span{
    display: inline-block;
    width: 30%;
}

.player-img img{
    width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
}

.confirm{
    position: absolute;
    top: 20%;
    left: 50%;
    color: #fc3;
    background: #1d2026;
    border-radius: 5px;
    padding: 1%;
    z-index: 300;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.board {
    position: absolute;
    min-width: 400px;
    min-height: 400px;
    transform: translate(-50%) scale(.8);
    -webkit-transform: translate(-50%) scale(.8);
    border: 2px solid #000;
    border-radius: 5px;
    padding: 2px;
    margin: auto;
    left: 50%;
}

.in-game-players, .in-game-spectators{
    position: absolute;
    width: 20%;
    min-width: 120px;
    max-width: 180px;
    max-height: 55vh;
    z-index: 9;
    overflow-x:hidden;
    overflow-y: auto; 
}

.in-game-spectators{
    right: 1%;
    width: 10%;
}


.in-game-notif-btn:hover, .onliners:hover {
    color: #fc3!important;
}

.in-game-player-avatar,.in-game-spectator-avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
}

.in-game-player-avatar img,.in-game-spectator-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.path-hover {
    position: absolute;
    display: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px solid black; 
    background: #FA098B;
    z-index: 20;
}

table {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 3px solid black; 
    border-collapse: separate;
}

table, th, td {
    /*border: 1px solid black; */
}

table tr:last-child td:first-child {
border-bottom-left-radius: 5px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

tr td:nth-child(7){
    width: 130px;
    overflow: hidden;
}

tr:nth-child(7) {
    height: 130px;
}

.soundfx {
    position: fixed;
    bottom: -100%;
}

.btn{ 
    border-radius: 4px !important;
    padding: 1% 4%;

}

.board .btn {
    border-radius: 50% !important;
}

.round-init .btn:hover {
    background: #fc3 !important;
    border: 1px solid #fc3;
}

.gamecontrols .btn {
    color: #C6C4C4;
}
  
.ingame-tools {

}

hr {
    background: #bbb;
}

#homes {
    position: absolute;
    width: 30%;
    height: 30%;
    left: 30%;
    top: 35%;
    transform: translateX(15%) rotate(-45deg );
}

.p_home {
    position: absolute;
    width: 70%;
    height: 70%;
}

.p_home:nth-child(1) {
    left:0;
    top: 0;
    margin-left: -20%;
    margin-top: -20%;

}
.p_home:nth-child(2) {
    right: 0;
    top: 0;
    margin-right: -20%;
    margin-top: -19%;
}
.p_home:nth-child(3) {
    right:0;
    bottom: 0;
    margin-right: -20%;
    margin-bottom: -20%;
}
.p_home:nth-child(4) {
    left: 0;
    bottom: 0;
    margin-left: -20%;
    margin-bottom: -20%;
}

.player_1 .homed-piece-container {
    position: absolute;
    bottom: 8px;
    right: -2px;
    height: 33%;
    width: 45%;
}

.player_2 .homed-piece-container {
    position: absolute;
    bottom: 8px;
    left: -2px;
    height: 33%;
    width: 45%;
}

.player_3 .homed-piece-container {
    position: absolute;
    top: 8px;
    left: -2px;
    height: 33%;
    width: 45%;
}

.player_4 .homed-piece-container {
    position: absolute;
    top: 8px;
    right: -2px;
    height: 33%;
    width: 45%;
}

.piece-move-options {
    position: absolute;
    width: 60%;
    top: -100%;
    left: 50%;
    text-align: left;
    background: #fff;
    transform: translateX(-50%);
}

.piece-move-options ul {
    padding: 1px;
}

.piece-move-options ul li{
    display: block;
    cursor: pointer;
    padding: 0 2px;
}

 .mt-100 {
     margin-top: 100px
 }

 .container {
     margin-top: 200px
 }

 #winner {
    position: absolute;
    top: 0;
    left:  0;
    width: 100%;
    height: 100%;
    z-index: 200;
 }

.modal.open {
    display: block;
    position: relative;
    background: rgba(0, 0, 0, 0.7);
    z-index: 0;
}

.card {
 position: relative;
 display: flex;
 width: 450px;
 flex-direction: column;
 min-width: 0;
 word-wrap: break-word;
 background-color: #fff;
 background-clip: border-box;
 border: 1px solid #d2d2dc;
 border-radius: 4px;
 -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
 -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
 box-shadow: 0px 0px 5px 0px rgb(161, 163, 164)
}

.modal-dialog {
    pointer-events: all !important;
}

 .card .card-body {
    padding: 1rem 1rem;
 }

 .card-body {
     flex: 1 1 auto;
     padding: 1.25rem;
     color: #999;
     text-align: center;
 }

 .card-body img{
    display: inline-block;
    width: 150px;
 }

 p {
     font-size: 14px
 }

 h4 {
     margin-top: 18px;
     color: #999;

 }

 .cross {
     padding: 10px;
     color: #d6312d;
     cursor: pointer;
 }

 .continue:focus {
     outline: none;
 }

 .continue {
     border-radius: 5px;
     text-transform: capitalize;
     font-size: 13px;
     padding: 8px 19px;
     cursor: pointer;
     color: #fff;
     background-color: #D50000
 }

 .continue:hover {
     background-color: #D32F2F !important
 }
/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) and (orientation: landscape){
/* Styles */
   
}

@media only screen and (min-width: 768px) {
    .board {
        width: 550px;
        height: 550px;
    }
}
</style>
