<template>

  <main>
<section class="hero">
                <div class="container">
                    <div class="hero-inner">
            <div class="hero-copy">
                          <h1 class="hero-title mt-0">About - LudiJam</h1>
                          <p class="hero-paragraph">This Ludi game by Jordain and Alex is awesome.</p>
                          <div class="hero-cta"><a class="button button-primary" href="/">Home</a><a class="button" href="/">Sign In</a></div>
            </div>
            <div class="hero-figure anime-element">
              <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                <rect width="528" height="396" style="fill:transparent;" />
              </svg>
              <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
              <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
              <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
              <div class="hero-figure-box hero-figure-box-05"></div>
              <div class="hero-figure-box hero-figure-box-06">
                <a class="box-btn" href="/" title="Play Game"> 
                    <i class="fa fa-dice fa-5x"></i>
                </a>
              </div>
              <div class="hero-figure-box hero-figure-box-07"></div>
              <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
              <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
              <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
            </div>
                    </div>
                </div>
            </section>

    <Footer></Footer>

  </main>

</template>
<script>
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

    export default {
        name: 'About',
        components: {
          Footer
        },
        setup(){
            useMeta({
              title: 'LudiJam - About',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
    };
</script>

