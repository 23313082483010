import { createApp } from 'vue';
import App from './App.vue';
import "./registerServiceWorker";

import router from './router';
import metaManager from './meta';
import axios from 'axios';
import sigcon from './plugins/vue-signalr';
import Toaster from '@meforma/vue-toaster';
import Vue3TouchEvents from "vue3-touch-events";

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/login.css';
import '@/assets/css/cards.css';
import '@/assets/css/style.css';
// Setup axios as the Vue default $http library

//ask user for mic permission


let optionAxios = {
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
axios.defaults.baseURL = 'https://ludijamapi.azurewebsites.net/api'; // same as the Url the server listens to
axios.defaults.withCredentials = true;

// Install Vue extensions
var app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.$imgPath = "https://ludijamstorage.blob.core.windows.net/ludijamblob/";
app.config.globalProperties.$http = axios;
app.config.globalProperties.$httpOptions = optionAxios;
app.config.globalProperties.$user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {IsAuthenticated: false, UserName: null, Email: null};
app.config.globalProperties.$n_players = localStorage.getItem('n_players') ? parseInt(localStorage.getItem('n_players')) : null;

// app.config.globalProperties.$ludi_board_plot = [[[61,62, 63, 64, 65, 66],[56, 46, 36, 26, 16],[71, 72, 73, 74, 75, 76]], [[18,28, 38, 48, 58, 68],[69, 610, 611, 612, 613],[17, 27, 37, 47, 57, 67]], [[813,812, 811, 810, 89, 88], [98, 108, 118, 128, 138],[713, 712, 711, 710, 79, 78]], [[136,126, 116, 106, 96, 86],[85, 84, 83, 82, 81],[137, 127, 117, 107, 97, 87]]];

app.config.globalProperties.$ludi_board_plot = [[[61,62, 63, 64, 65, 66],[16,26,36,46,56],[71, 72, 73, 74, 75, 76]], [[18,28, 38, 48, 58, 68],[613,612,611,610,69],[17, 27, 37, 47, 57, 67]], [[813,812, 811, 810, 89, 88], [138,128,118,108,98],[713, 712, 711, 710, 79, 78]], [[136,126, 116, 106, 96, 86],[81,82,83,84,85],[137, 127, 117, 107, 97, 87]]];
app.config.globalProperties.$piece_direct_path = [[62, 63, 64, 65, 66, 56, 46, 36, 26, 16, 17, 18, 28, 38, 48, 58, 68, 69, 610, 611, 612, 613, 713, 813, 812, 811, 810, 89, 88, 98, 108, 118, 128, 138, 137, 136, 126, 116, 106, 96, 86, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75, 76,1000], [28, 38, 48, 58, 68, 69, 610, 611, 612, 613, 713, 813, 812, 811, 810, 89, 88, 98, 108, 118, 128, 138, 137, 136, 126, 116, 106, 96, 86, 85, 84, 83, 82, 81, 71, 61, 62, 63, 64, 65, 66, 56, 46, 36, 26, 16, 17, 27, 37, 47, 57, 67,2000], [812, 811, 810, 89, 88, 98, 108, 118, 128, 138, 137, 136, 126, 116, 106, 96, 86, 85, 84, 83, 82, 81, 71, 61, 62, 63, 64, 65, 66, 56, 46, 36, 26, 16, 17, 18, 28, 38, 48, 58, 68, 69, 610, 611, 612, 613, 713, 712, 711, 710, 79, 78,3000], [126, 116, 106, 96, 86, 85, 84, 83, 82, 81, 71, 61, 62, 63, 64, 65, 66, 56, 46, 36, 26, 16, 17, 18, 28, 38, 48, 58, 68, 69, 610, 611, 612, 613, 713, 813, 812, 811, 810, 89, 88, 98, 108, 118, 128, 138, 137, 127, 117, 107, 97, 87,4000]];
app.config.globalProperties.$full_path = ["62", "63", "64", "65", "66", "56", "46", "36", "26", "16", "17", "18", "28", "38", "48", "58", "68", "69", "610", "611", "612", "613", "713", "813", "812", "811", "810", "89", "88", "98", "108", "118", "128", "138", "137", "136", "126", "116", "106", "96", "86", "85", "84", "83", "82", "81", "71", "72", "73", "74", "75", "76", "61", "27", "37", "47", "57", "67", "712", "711", "710", "79", "78", "127", "117", "107", "97", "87"];
app.use(sigcon);
app.use(Toaster, {
    position: 'top'
});
app.use(Vue3TouchEvents);
app.use(metaManager);
/* eslint-disable no-new */
app.use(router, axios).mount('#app');


