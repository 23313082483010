<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : 'LudiJam' }}</template>
    </metainfo>
    <div v-cloak>
        <span class="v-cloak--inline">Loading...</span>
        <!-- <header /> -->

        <div class="v-cloak--hidden">
            <header class="site-header">
                <div class="container">
                    <div class="site-header-inner">
                        <div class="brand header-brand">
                            <h1 class="mt-0">
                                <router-link to="/">
                                    <img class="brand_logo header-logo-image" src="@/assets/logo.png" alt="LudiJam Logo">
                                </router-link>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>

            <router-view />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { useMeta, getCurrentManager } from 'vue-meta';
  
    export default {
        name: 'App',
        setup(){
            useMeta({
                title: 'LudiJam - Home',
                meta: [
                    {charset: 'utf-8' },
                    {name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    {name: 'audience', content: 'all'},
                    {name: 'distribution', content: 'global'},
                    {name: 'description', content: 'Play Ludi Online with friends and family from anywhere you have an internet connection.'},
                    {name: 'DC.title', content: 'www.ludijam.com'},

                    {property: 'og:title', content: "LudiJam - Jamaican Ludi game online"},
                    {property: 'og:site_name', content: 'LudiJam'},
                    {property: 'og:type', content: 'website'},    
                    {name: 'robots', content: 'index,follow'},
                    {property: 'og:url', content: 'https://www.ludijam.com/'},
                    {property: 'og:image', content: './assets/logo.png' } 
                ],
                htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
        data() {
            return {
         
            }
        },
        created(){
            let app_ = this;
            this.$sigcon.start().catch(() => {
        
            });   
        },
    };
</script>

<style>
[v-cloak] .v-cloak--block {
  display: block;
}
[v-cloak] .v-cloak--inline {
  display: inline;
}
[v-cloak] .v-cloak--inlineBlock {
  display: inline-block;
}
[v-cloak] .v-cloak--hidden {
  display: none;
}
[v-cloak] .v-cloak--invisible {
  visibility: hidden;
}
.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
  display: none;
}

    .user-avatar {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: 1%;
    }

    .user-avatar img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .in-game-nav-bar {
        padding: 1%;
    }

    .in-game-notif-btn {
        border: none;
        outline: none;
        color: #4856fd;
        background: transparent;
    }
    .minimize {

        color: orange;
        cursor: pointer;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #5b7d9e!important;
        width: 100%;
    }
</style>


