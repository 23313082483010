let peers = {};
var hark = require('hark/hark.js');

function InitListeners (connection){

    window.addEventListener('mediaDeviceReady', () =>{
        if(localStorage.getItem('gameSession')){
            connection.invoke('AcceptPermission');
        }
    })

    connection.on('PermissionAccepted' , (player) => {
            
        let message = {
            sender: JSON.parse(localStorage.getItem('playerState')),
            receiver: player
        };

        createPeerConnection(player,connection);
        connection.invoke('CreatePeerConnection',message);
    });

    connection.on('PeerConnectionCreated', (negotiation) => {

        let currentUser = JSON.parse(localStorage.getItem('playerState'));

        createPeerConnection(negotiation.sender,connection);

        let sender = negotiation.sender;

        if(currentUser.PlayerPosition < sender.PlayerPosition)
        {
            let message = {
                sender: sender,
                receiver: currentUser
            };
        
            connection.invoke('DirectRequestCall', message);
        }
        else
        {
            let message = {
                sender: currentUser,
                receiver: sender
            };
        
            connection.invoke('DirectRequestCall', message);
        }
    });

    connection.on('CallRequested', async (negotiation) => {
        await createAnswer(negotiation,connection);
    });

    connection.on('DirectCallRequested' , async (negotiation) => {
        if(negotiation.useReceiver){
            await createOffer(negotiation.receiver,connection);
        }else{
            await createOffer(negotiation.sender,connection);
        }
        
    });

    connection.on('AnsweredCall', (negotiation) => {
        acceptCall(negotiation);
        
    });

    connection.on('AcceptICECandidate', async (negotiation) => {
        await addIceCandidate(negotiation);
        
    });

}

function createPeerConnection(player,connection){

    let peer = new RTCPeerConnection(getServers());
    window.rtcstream.getTracks().forEach(track => {
        peer.addTrack(track, window.rtcstream);
    });
    let createAudio = document.createElement('audio');
    createAudio.setAttribute("id",player.UserId);
    createAudio.setAttribute("hidden",true);
    document.querySelector("body").appendChild(createAudio);
    addPeerEventhandlers(peer,player,connection);
    peers[player.UserId] = peer;
    
}

async function createOffer(player, connection){

    let currentUser = JSON.parse(localStorage.getItem('playerState'));

    const offer = await peers[player.UserId].createOffer();
    await peers[player.UserId].setLocalDescription(offer);

    let message = {
        sender: currentUser,
        receiver: player,
        packet: offer
    };

    connection.invoke('RequestCall', message);
    
}

async function createAnswer(negotiation,connection){

    let currentUser = JSON.parse(localStorage.getItem('playerState'));

    let sender = negotiation.sender;

    if(sender.PlayerPosition < currentUser.PlayerPosition)
    {
        peers[sender.UserId].setRemoteDescription(new RTCSessionDescription(negotiation.packet));

        const answer = await peers[sender.UserId].createAnswer();
        await peers[sender.UserId].setLocalDescription(answer);

        let message = {
            sender: currentUser,
            receiver: sender,
            packet: answer
        };

        connection.invoke('AnswerCall', message);
    }
    else
    {
        let message = {
            sender: sender,
            receiver: currentUser
        };
    
        connection.invoke('DirectRequestCall', message);
    }

}

function addPeerEventhandlers(peer,user,connection){

    let currentUser = JSON.parse(localStorage.getItem('playerState'));

    peer.onicecandidate = (event) => {
        if (event.candidate) {
            let message = {
                sender: currentUser,
                receiver: user,
                candidate: event.candidate
            }

            connection.invoke('ShareICECandidate',message);
        }
    }

    //uncomment when debugging connection states

    // peer.onconnectionstatechange = () => {
    //     console.log("peer connection state ",peer.connectionState);
    // }

    peer.addEventListener('track', (event) => {
        let [stream] = event.streams;

        document.getElementById(user.UserId).srcObject = stream;

        document.getElementById(user.UserId).play();


        let speechEvents = hark(stream, {});

        speechEvents.on('speaking', ()=> {
            let talkingEvent = new CustomEvent('IsTalking',{detail: user.UserId});
            window.dispatchEvent(talkingEvent);
        });

        speechEvents.on('stopped_speaking', ()=> {
            let talkingEvent = new CustomEvent('IsNotTalking',{detail: user.UserId});
            window.dispatchEvent(talkingEvent);
        });
        
    });

}

function acceptCall(negotiation){
    peers[negotiation.sender.UserId].setRemoteDescription(new RTCSessionDescription(negotiation.packet));
    
}

async function addIceCandidate(negotiation){
    await peers[negotiation.sender.UserId].addIceCandidate(negotiation.candidate);
}

function getServers(){
    return {
        iceServers: [
            {
                urls: [
                    "stun:us-turn14.xirsys.com"
                ]
            },
            {
                urls:
                [
                    "turn:us-turn14.xirsys.com:80?transport=udp",
                ],
                username: "mAGqdgmIBD8Yp_OzAan-jmgXdR8iZoabNNE203EPJof7Fri82G8gMbw99Na6qZ7vAAAAAGCmoDNsdWRpamFt",
                credential: "2789db3e-b993-11eb-a9da-0242ac120004",
            }
        ]
    }
}

module.exports = {
    InitListeners: InitListeners,
    Peers: peers
}