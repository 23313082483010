<template>
    
        <main>
             <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">
 <div class="login vertical-center">
            <div class="inner-block">
            <form>
                <h3>LudiJam Login</h3>

                <div class="form-group">
                    <label>User Name</label>
                    <input type="text" v-model="username" class="form-control form-control-lg" required/>
                </div>

                <div class="form-group">
                    <label>Password</label>
                    <input type="password" v-model="password" class="form-control form-control-lg" required/>
                </div>

                <button type="submit" @click="LoginHandler" class="btn btn-dark btn-lg btn-block">Sign In</button>
               
                <p class="forgot-password">
                    <router-link to="/forgot-password" class="float-right">Forgot password?</router-link>
                    <router-link to="/register" class="float-left">Not registered?</router-link>
                </p>

            </form>
            </div>
            </div>
    
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06"></div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>

           
    <Footer></Footer>
    </main>

</template>

<script >
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

    export default {
        name: "Login",
        components: {
            Footer
        },
        setup(){
            useMeta({
              title: 'LudiJam - Login',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
        data() {
            return {
                username: "",
                password: "",
                activation_code: "",
                verify_counter: 0,
                optionAxios: this.$httpOptions
            }
        },mounted(){
           
        },
        methods: {
            LoginHandler(e) {
                e.preventDefault();
                var login_ = this;
                if (this.username.length > 0 && this.password.length > 0) {
                    
                    login_.$toast.show("<span class='fa fa-spinner fa-2x'></span> Loading....",{
                        position: 'bottom'
                    });
                  
                    this.$http.post('/user/Login', { UserName: this.username, Password: this.password }, this.optionAxios)
                        .then(response => {
                            let user = response.data.Data;
                            let user_is_admin = user.role ? true : false;
    
                            localStorage.setItem('user', JSON.stringify(user));
                            login_.$toast.clear();
                            
                            if(!user.IsActivated){
                                // window.location.href = '/verification';       
                                login_.$router.push({ name: 'VerifyAccount' });                      
                            }else{
                                localStorage.setItem('jwt', user.UserId);
                                if (localStorage.getItem('jwt') != null) {
                                    login_.$toast.clear();
                                    login_.$toast.success('Logged in successfully.');
                                    let user_ = JSON.parse(localStorage.getItem('user'));
                                    user_["IsAuthenticated"] = true;
                                    localStorage.setItem('user', JSON.stringify(user_))
                                    if (user_is_admin) {
                                        login_.$router.push({ name: 'Admin' });                      
                                    }
                                    else {
                                        window.location.href = '/';
                                    }
                                }
                            }
                        })
                        .catch(function (error) {
                            login_.$toast.clear();
                            if(error.response){
                                login_.$toast.error(`${error.response.data.ErrorMessage}`,{
                                    duration: false,
                                    dismissible: true
                                });
                            }else{
                                 login_.$toast.error(`${error}`);
                            }
                  
                        });
                } else {
                    login_.$toast.clear();
                    login_.$toast.error("All fields are required");
                }
            },
        }
    };
</script>



