<template>
    <main>
        <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">

                            <div class="error_404">
                                <div class="site">
                                    <div class="sketch">
                                        <div class="bee-sketch">
                                            <a class="navbar-brand float-left" href="/">
                                                <img src="../assets/logo.png" />
                                                </a>
                                        </div>
                                    </div>

                                    <h1>
                                        404:
                                        <small>Players Not Found</small>
                                        <router-link class="nav-link pr-3" to="/">Home</router-link>
                                    </h1>
                                 
                                </div>       

                                </div>
                        <!--     <h1 class="hero-title mt-0">Landing template for startups</h1>
                            <p class="hero-paragraph">Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.</p>
                            <div class="hero-cta"><a class="button button-primary" href="#">Pre order now</a><a class="button" href="#">Get in touch</a></div> -->
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06"></div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>
    
<Footer></Footer>
    </main>
</template>
<script>
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

    export default {
        name: '404',
        components: {
            Footer
        },setup(){
            useMeta({
              title: 'LudiJam - 404 | Page not found',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
    };
</script>
<style scoped lang="scss">
    

    html {
        height: 100%;
    }

    body {
        min-height: 100%;
    }

    body {
        display: flex;
    }

    h1 {
        font-family: 'Cabin Sketch', cursive;
        font-size: 3em;
        text-align: center;
        opacity: .8;
        order: 1;
    }

        h1 small {
            display: block;
        }

    .site {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10%;
    }


    .sketch {
        position: relative;
        height: 400px;
        min-width: 400px;
        margin: 0;
        overflow: visible;
        order: 2;
    }

    .bee-sketch {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen and (min-width: 780px) {
        .site {
            flex-direction: row;
          
        }

        h1 {
            text-align: right;
            order: 2;
            padding-bottom: 2em;
            padding-left: 2em;
        }

        .sketch {
            order: 1;
        }
    }


    
</style>