<template>
	   <main>
             <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">
 <div class="login vertical-center">
            <div class="inner-block">
            <form>
                <h3>LudiJam Feedback</h3>

                <div class="form-group">
                    <label>User Name</label>
                    <input type="text" class="form-control form-control-lg" :placeholder="$user.UserName" disabled/>
                </div>

                <div class="form-group">
                    <label>Feedback / Suggestions</label>
                    <textarea v-model="feedback" class="form-control form-control-lg" placeholder="....." required>
                    </textarea>
                </div>

                <button type="submit" @click="FeedbackHandler" class="btn btn-dark btn-lg btn-block">Send</button>
               
                <p class="forgot-password">
                    <router-link to="/" class="float-right">Back to game</router-link>
                </p>

            </form>
            </div>
            </div>
    
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06">
                            	<router-link to="/" class="box-btn" title="Back to game"><i class="fa fa-dice fa-5x"></i>
                            	</router-link>
                            </div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg">
                    			
                            </div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>

           
    <Footer></Footer>
    </main>
</template>
<script>
	import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

	 export default {
        name: "Feedback",
        components: {
            Footer
        },
		setup(){
            useMeta({
              title: 'LudiJam - Feedback',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
        data(){
            return {
                feedback: '',
                gameSession: localStorage.getItem('gameSession') ? localStorage.getItem('gameSession') : null,
            }
        },
        methods: {
        	FeedbackHandler(e){
        		e.preventDefault()
        		let feedback_ = this;
        		feedback_.$toast.clear();
        		if(feedback_.feedback){
        			feedback_.$http.post('/feedback/create', { UserName: this.$user.UserName, Message: feedback_.feedback, SessionId: feedback_.gameSession, DateCreated: new Date()}, this.$httpOptions)
                        .then(response => {
                        	console.log(response)
                        	feedback_.feedback = '';
		 	            	feedback_.$toast.success('Feedback sent.');
		 	            	feedback_.$toast.info('Thank you for your contribution.');
		 	            }).catch(err=>{
		 	            	feedback_.$toast.error(`${err}`);
		 	            })
        		}
        	}
        }
    }
</script>
<style type="text/css">
	
</style>