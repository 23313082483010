<template>
    <div class="notifications_">
      <span class="float-right close-btn" @click="closeNoticationDialog"><i class="fa fa-times fa-2x"></i></span>
      <h3>Notifications</h3>
      <div class="inner pb-5">
        <h3>Invites</h3>
        <div class="container d-flex justify-content-center" v-for="notification in notifications.invites" :key="notification.id">
          <div class="card mt-2 p-3">
            <div class="media"> <span class='sender-avatar'><img :src="notification.SenderAvatar ? $imgPath + notification.SenderAvatar : '/img/avatar.png'" /></span> <span class="invitee mr-3"></span>
                <div class="media-body">
                    <p class="mt-2 mb-0 text"><small>{{ notification.Description }}</small></p> <p>{{moment(notification.InvitationTime).format('DD.MM.YYYY@HH:mm')}}</p>
                </div><span class="invitee mr-3 ml-3" title="Accept this invite" @click="acceptInvite(notification)"><i class="fa fa-check-square fa-3x"></i></span>
            </div>
          </div>
        </div>
        <hr>
        
        <h3>Requests</h3>
        <div class="container d-flex justify-content-center" v-for="notification in notifications.requests" :key="notification.id">
          <div class="card mt-2 p-3">
            <div class="media"> <span class='sender-avatar'><img :src="notification.SenderAvatar ? $imgPath + notification.SenderAvatar : '/img/avatar.png'" /></span> <span class="invitee mr-3"></span>
                <div class="media-body">
                    <p class="mt-2 mb-0 text"><small>{{ notification.Description }}</small></p> <p>{{moment(notification.InvitationTime).format('DD.MM.YYYY@HH:mm')}}</p>
                </div><span class="invitee mr-3 ml-3" title="Accept Friend Request" @click="acceptFriendRequest(notification)"><i class="fa fa-check-square fa-3x"></i></span>
            </div>
          </div>
        </div>
    </div>
    <button @click="optionSelectout.play" ref="option-select-sound" class="soundfx"></button>
  </div>
</template>

<script>
/* eslint-disable */
    import { useSound } from '@vueuse/sound';
    import moment from 'moment';
    import notifyAlertfx from '@/assets/sounds/NotifyAlert.mp3';
    import optionSelectfx from '@/assets/sounds/OptionSelect.mp3';

    export default {
        name: "Notifications",
        props: ["notifications"],
        setup(){
            const  notifyAlertout = useSound(notifyAlertfx);
            const  optionSelectout = useSound(optionSelectfx);
            return {
                notifyAlertout,optionSelectout
            };
        },
        data(){
        	return {
        		
        	}
        },created(){
            this.moment = moment;
        },
        methods: {
          acceptFriendRequest(notif){
            this.$refs['option-select-sound'].click();
            this.$sigcon.invoke("AcceptFriendRequest",notif);
          },
          acceptInvite(notif){
            this.$refs['option-select-sound'].click();
            this.$sigcon.invoke("AcceptInvitation",notif);
            this.$parent.$refs.close_notif_dialog.click();
          },
          closeNoticationDialog(){
            this.$refs['option-select-sound'].click();
            this.$parent.$refs.close_notif_dialog.click();
          }
        }
    };
</script>

<style scoped>
.soundfx {
    position: fixed;
    bottom: -100%;
}
  .sender-avatar {
    width: 80px;
    height: 80px;
  }
  .sender-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .close-btn {
    color: #fff;
  }

  .close-btn:hover {
    color: #fc3;
  }
</style>