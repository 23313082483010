<template>
		<section class="cta section">
				<div class="container">
					<div class="cta-inner section-inner">
                    <form @submit="addSubscriber">
                        <div class="input-group mb-3">
                          <input type="email" v-model="subscriber" class="form-control" placeholder="Subscribe to the Awesomeness." aria-label="Subscribe" aria-describedby="set-sub" required>
                          <div class="input-group-append">
                            <button type="submit" class="input-group-text button-primary" id="set-sub" >Subscribe</button>
                          </div>
                        </div>
                    </form>
					</div>
				</div>
			</section>
          <footer class="site-footer">
            <div class="container">
                <div class="site-footer-inner">
                    <div class="brand footer-brand">
                        <router-link to="/">
                            <img class="brand_logo header-logo-image" src="@/assets/logo.png" alt="LudiJam Logo">
                        </router-link>
                    </div>
                    <ul class="footer-links list-reset">
                        <li>
                            <a href="#">Contact</a>
                        </li>
                        <li>
                            <a href="/about">About us</a>
                        </li>
                        <li>
                            <a href="#">FAQ's</a>
                        </li>
                        <li>
                            <a href="#">Support</a>
                        </li>
                    </ul>
                    <ul v-if="hasSocial" class="footer-social-links list-reset">
                        <li>
                            <a href="#">
                                <span class="screen-reader-text">Facebook</span>
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" fill="#0270D7"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span class="screen-reader-text">Twitter</span>
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" fill="#0270D7"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <span class="screen-reader-text">Google</span>
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" fill="#0270D7"/>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    <div class="footer-copyright text-white">LudiJam 2021 &copy; all rights reserved</div>
                </div>
            </div>
        </footer>
</template>

<script >
	export default {
		name: "Footer",
		data(){
        	return {
				subscriber: null,
				hasSocial: false,
			}
        }, 
        methods: {
            addSubscriber(e){
                e.preventDefault();
                let footer_ = this;
                if(footer_.subscriber){
                    footer_.$toast.show("<span class='fa fa-spinner fa-2x'></span> Subscribing....",{
                        position: 'bottom',
                        duration: false,
                        dismissible: true
                    });
                }
                footer_.$http.post('/user/Subscribe', {"Email":footer_.subscriber}, footer_.optionAxios)
                        .then(()=> {
                            footer_.$toast.clear();
                            footer_.$toast.success("<span class='fa fa-check fa-2x'></span> You have been subscribed to the awesomeness!!!. We will be in touch");
                        }).catch((error)=> {
                            footer_.$toast.clear();
                            if(error.response){
                                footer_.$toast.error(`${error.response.data.ErrorMessage}`,{
                                    duration: false,
                                    dismissible: true
                                });
                            }else{
                                footer_.$toast.error(`${error}`);
                            }
                        });

            }
        }
	};
</script>