<template>
    <main>
              <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">
                            <div class="login vertical-center">
                                <div class="inner-block">
                                <form>
                                    <h3>Verify Account</h3>

                                   <div class="form-group" >
                                        <label>Activation Code</label>
                                        <input type="text" v-model="activation_code" class="form-control form-control-lg" required/>
                                    </div>
                                    <button type="submit" @click="ActivationHandler" class="btn btn-dark btn-lg btn-block">Verify</button>
                                    
                                </form>
                                </div>
                            </div>
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06"></div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>
   <Footer></Footer>
</main>
</template>

<script >
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

    export default {
        name: "VerifyAccount",
        components: {
            Footer
        },setup(){
            useMeta({
              title: 'LudiJam - Account Verification',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
        data() {
            return {
                activation_code: "",
                verify_counter: 0,
                optionAxios: this.$httpOptions
            }
        },
        methods: {
            IssueActivationCode(){
                let login_ = this;
                 this.$http.get('/user/IssueActivationCode',this.optionAxios)
                 .then(() => {
                    login_.$toast.error("Verification attempts exceeded!!!");
                    login_.$toast.info("Check your email for a new verification code.");
                 }).catch(function (error) {
                      if(error.response)
                        login_.$toast.error(`${error.response.data.ErrorMessage}`);
                    else
                        login_.$toast.error(`${error}`);
                 });
            },
            ActivationHandler(e){
                e.preventDefault()
                var login_ = this;
                let user_token = false;
                let user_ = {IsAuthenticated: false};
                if(localStorage.getItem('user') != null){
                    user_ = JSON.parse(localStorage.getItem('user'));
                    user_token = user_.UserId;
                }
                if(!user_token){
                    localStorage.removeItem('user');
                    localStorage.removeItem('jwt');

                    window.location.href = '/login';
                }else{
                    if (this.activation_code.length > 0) {
                         login_.$toast.show("<span class='fa fa-spinner fa-2x'></span> Loading....",{
                            position: 'bottom',
                            duration: false,
                            dismissible: false
                        });

                        this.$http.post('/user/ActivateAccount', { ActivationCode: this.activation_code }, this.optionAxios).then(response => {
                            login_.$toast.clear();
                            var data = response.data.Data;
                            user_["IsAuthenticated"] = true;
                            localStorage.setItem('jwt', user_.UserId);
                            localStorage.setItem('user', JSON.stringify(user_))
                            localStorage.setItem('token_expiry', data.Expiry);
                            window.location.href = '/';
                        }).catch(function () {
                            login_.verify_counter++;
                            login_.$toast.clear();
                            
                            if(login_.verify_counter == 3){
                                login_.IssueActivationCode();
                            }else{
                                login_.$toast.clear();
                                login_.$toast.error("Invalid Token!!! Try again.",{
                                    duration: false,
                                    dismissible: true
                                });
                            }
                        });
                    }else{
                        login_.$toast.error("Verification code cannot be empty.");
                    }
                }
            },
        }
    };
</script>
