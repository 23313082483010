<template>

    <main>
         <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">
                            <div class="login vertical-center">
       
        <div class="inner-block">
            <form>
                <h3>LudiJam Registration</h3>

                <div class="form-group">
                    <label>Email address</label>
                    <input type="email" v-model="email" class="form-control form-control-lg" required />
                </div>

                <div class="form-group">
                    <label>Password</label>
                    <input type="password" v-model="password" pattern=".{6,25}" class="form-control form-control-lg" required/>
                </div>
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input type="password" v-model="password_confirmation" class="form-control form-control-lg" required/>
                </div>

                <button type="submit" @click="RegisterHandler" class="btn btn-dark btn-lg btn-block">Sign Up</button>

                <p class="forgot-password">
                    <span class="float-left">Already registered?</span>
                    <router-link class="float-right" :to="{name: 'Login'}">Sign in</router-link>
                </p>
            </form>
        </div>
    </div>
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06"></div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>

    <Footer></Footer>
</main>
</template>

<script>
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 

    export default {
        name: "Register",
        props: ["nextUrl"],
        components:{
            Footer
        },setup(){
            useMeta({
              title: 'LudiJam - Account Registration',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
        data() {
            return {
                email: "",
                password: "",
                password_confirmation: "",
                is_admin: null
            }
        },
        methods: {
            RegisterHandler(e) {
                e.preventDefault()
                var login_ = this;
                if (this.password === this.password_confirmation && this.password.length > 0) {
                    login_.$toast.show("<i class='fa fa-spinner fa-2x'></i> Loading....", {
                        position: 'bottom'
                    });
                    this.$http.post('/user/RegisterUser', {
                        EmailAddress: this.email,
                        Password: this.password
                    })
                        .then(() => {
                            login_.password = "";
                            login_.email = "";
                            login_.password_confirmation = "";
                            login_.$toast.clear();
                            login_.$toast.success("Registration successful.");
                            login_.$toast.success("Check your email for your username and activate your account.");
                            login_.$toast.show("<a href='/' class='btn btn-info btn-block'>Home</a> or <a href='/login' class='btn btn-success btn-block'>Login</a>", {
                                duration: false,
                                dismissible: true
                            });
                        })
                        .catch(function (error) {
                            login_.password = "";
                            login_.password_confirmation = "";
                            login_.$toast.clear();
                            login_.$toast.error(`${error.response.data.ErrorMessage}`, {
                                duration: false,
                                dismissible: true
                            });
                        });
                } else {
                    this.password = "";
                    this.passwordConfirm = "";

                    this.$toast.error(`Passwords do not match.`);
                }
            },


        }
    };

    </script>