<template>
    <Notifications :notifications="notifications" v-if="showNotifs"></Notifications>
	
         <main @mousemove="resetActivityTimer">
            <section class="hero">
                <div class="container">
                    <div class="hero-inner">
						<div class="hero-copy">
	                        <h1 class="hero-title mt-0">Welcome to LudiJam.com</h1>
	                        <p class="hero-paragraph">Here you can play ludo online with friends and family from anywhere you have an internet connection.</p>
	                        <div class="hero-cta">
	                        	<router-link v-if="!$user.IsAuthenticated" class="button button-primary" to="/login">Sign in</router-link>
	                        	<router-link v-if="!$user.IsAuthenticated" class="button" to="/register">Sign up</router-link>
	                        </div>
						</div>
						<div class="hero-figure anime-element">
							<svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
								<rect width="528" height="396" style="fill:transparent;" />
							</svg>
							<div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
							<div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg">
								
							</div>
							<!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
							<div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
							<div class="hero-figure-box hero-figure-box-05">
									<div v-if="$user.IsAuthenticated" class="user-box">
								
				                    <a ref="logout" class="float-right text-white htl" href="#" @click.prevent="Logout" title="Logout">
				                        <i class="fa fa-power-off"></i>
				                    </a>

									<button v-if="available" class="btn btn-default offline htl" title="I am not available"  :disabled="gameInProgress" @click="changeStatus(2)">
									   <i class="fa fa-circle"></i>
									</button>
									<button v-if="!available" class="btn btn-default available htl" title="I am available"  :disabled="gameInProgress" @click="changeStatus(4)">
									   <i class="fa fa-circle"></i>
									</button>

				                    <span class="user-avatar-home htl" :title="$user.UserName">
				                    	<router-link to="/profile">
				                       		<img :src="$user.Avatar ? $imgPath + $user.Avatar : '/img/avatar.png'" />
				                   		</router-link>
				                    </span>
&nbsp;&nbsp;
				                    <button type="button" ref="show_notifs" @click="showNotifications" class="box-btn-cuz htl">
                       				 <i class="fa fa-envelope"></i> <span class="new" v-if="newNotifs">new</span>
				                    </button>
				                    <button type="button" ref="close_notif_dialog" class="box-btn-cuz" @click="closeNotifications" v-if="showNotifs">
				                        <i class="fa fa-times"></i>
				                    </button>
			                	</div>
							</div>
							<div class="hero-figure-box hero-figure-box-06">
								<button class="box-btn" v-if="!$user.IsAuthenticated" @click="loginToPlayLudi" title="Login to play Ludi"> 
                        			<i class="fa fa-dice fa-5x"></i>
                  				</button>
                  				<button v-else class="box-btn" ref="open-game-board" @click="playLudi" > 
			                    	<i class="fa fa-dice fa-5x"></i>
			                  	</button>
							</div>
							<div class="hero-figure-box hero-figure-box-07">
								<button class="box-btn" v-if="!$user.IsAuthenticated" @click="loginToPlayLudi" title="Login to play Ludi"> 
                        			<i class="fa fa-dice fa-2x"></i>
                  				</button>
                  				<button v-else class="box-btn" @click="playLudi"> 
			                    	<i class="fa fa-dice fa-2x"></i>
			                  	</button>
							</div>
							<div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
							<div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg">
								<button class="box-btn" v-if="!$user.IsAuthenticated" @click="loginToPlayLudi" title="Login to play Ludi"> 
                        			<i class="fa fa-dice"></i>
                  				</button>
                  				<button v-else class="box-btn" @click="playLudi"> 
			                    	<i class="fa fa-dice"></i>
			                  	</button>
							</div>
							<div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
						</div>
                    </div>
                </div>
            </section>

            <section v-if="hasContent" class="features section">
                <div class="container">
					<div class="features-inner section-inner has-bottom-divider">
                        <div class="features-wrap">
                            <div class="feature text-center is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img src="@/assets/images/feature-icon-01.svg" alt="Feature 01">
                                    </div>
                                    <h4 class="feature-title mt-24"></h4>
                                    <p class="text-sm mb-0"></p>
                                </div>
                            </div>
							<div class="feature text-center is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img src="@/assets/images/feature-icon-02.svg" alt="Feature 02">
                                    </div>
                                    <h4 class="feature-title mt-24"></h4>
                                    <p class="text-sm mb-0"></p>
                                </div>
                            </div>
                            <div class="feature text-center is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img src="@/assets/images/feature-icon-03.svg" alt="Feature 03">
                                    </div>
                                    <h4 class="feature-title mt-24"></h4>
                                    <p class="text-sm mb-0"></p>
                                </div>
                            </div>
                            <div class="feature text-center is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img src="@/assets/images/feature-icon-04.svg" alt="Feature 04">
                                    </div>
                                    <h4 class="feature-title mt-24"></h4>
                                    <p class="text-sm mb-0"></p>
                                </div>
                            </div>
							<div class="feature text-center is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img src="@/assets/images/feature-icon-05.svg" alt="Feature 05">
                                    </div>
                                    <h4 class="feature-title mt-24"></h4>
                                    <p class="text-sm mb-0"></p>
                                </div>
                            </div>
                            <div class="feature text-center is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img src="@/assets/images/feature-icon-06.svg" alt="Feature 06">
                                    </div>
                                    <h4 class="feature-title mt-24"></h4>
                                    <p class="text-sm mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="hasContent" class="pricing section">
                <div class="container-sm">
                    <div class="pricing-inner section-inner">
                        <div class="pricing-header text-center">
                            <h2 class="section-title mt-0"></h2>
                            <p class="section-paragraph mb-0"></p>
                        </div>
						<div class="pricing-tables-wrap">
                            <div class="pricing-table">
                                <div class="pricing-table-inner is-revealing">
                                    <div class="pricing-table-main">
                                        <div class="pricing-table-header pb-24">
                                            <div class="pricing-table-price"><span class="pricing-table-price-currency h2">$</span><span class="pricing-table-price-amount h1">49</span><span class="text-xs">/month</span></div>
                                        </div>
										<div class="pricing-table-features-title text-xs pt-24 pb-24"></div>
                                        <ul class="pricing-table-features list-reset text-xs">
                                            <li>
                                                <span></span>
                                            </li>
                                            <li>
                                                <span></span>
                                            </li>
                                            <li>
                                                <span></span>
                                            </li>
											<li>
												<span></span>
											</li>
                                        </ul>
                                    </div>
                                    <div class="pricing-table-cta mb-8">
                                        <a class="button button-primary button-shadow button-block" href="#"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    <div id="settings" v-if="enableSettings">
        <div class="container">
        
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                 <span class="text-white act float-right" @click="closeSettings" v-if="enableSettings"  title="player settings">
                            <i class="fa fa-times"></i>
                        </span>
            <div class="card h-100">
            
                <div class="card-body">

                    <div class="row gutters">
                     
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h6 class="mb-3 text-primary">Board Skin</h6>
                            <ul>
                                <li class="theme" v-for="theme in themes" :key="theme.name" >
                                    <div :class="[user_theme == theme.name ? 'in-use' : 'use-theme']" :disabled="user_theme == theme.name ? true : false" :title="user_theme == theme.name ? 'In use' : 'Use this Skin' " @click="useSelectedTheme(theme.name)" >
                                        <span class="theme-name">{{ theme.name }}</span>
                                        <div class="theme-thumb">
                                            <img :src="require('@/assets/images/themes/'+theme.thumbnail)" :alt="'LudiJam '+theme.name+' theme'"/>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                         
                </div>
            </div>
        </div>
    </div>
    </div>

		<div class="ludiboard" v-if="playGame">
           <div class="in-game-nav-bar">
                <a class="float-left" href="/" @click="handleHomeNav">
                    <img class="brand_logo" src="@/assets/logo.png" alt="LudiJam Logo" />
                </a>
                <div class="float-left">
                    <span class="user-avatar act" :title="$user.UserName">
                        <router-link v-if="!$user.GuestUser" to="/profile">
                            <img :src="$user.Avatar ? $imgPath + $user.Avatar : '/img/avatar.png'" />
                        </router-link>
                        <img v-else :src="$user.Avatar ? $imgPath + $user.Avatar : '/img/avatar.png'" />
                    </span>&nbsp;
                      <span class="text-white act" @click="showSettings" v-if="!enableSettings && !$user.GuestUser"  title="player settings">
                        <i class="fa fa-cogs"></i>
                    </span>&nbsp;
                    <button type="button" @click="showNotifications" title="Open Notifications" class="in-game-notif-btn act">
                        <i class="fa fa-envelope"></i> <span class="new" ref="notif_count"></span> 
                    </button>
                    
                  

                    <span class="onliners text-white act" @click="openOnliners" v-if="!showOnliners"  title="show online players">
                        <i class="fa fa-users"></i>
                    </span>&nbsp;
                   
                    <span ref="close_onliners" class="onliners text-white act" @click="closeOnliners" v-if="showOnliners" title="hide online players">
                        <i class="fa fa-users-slash"></i>
                    </span>&nbsp;
                </div>
              
                    <router-link class="box-btn white-hover" to="/license" title="View all attributions for assets used in this game"> 
                        <i class="fa fa-closed-captioning"></i>
                    </router-link>
                &nbsp;&nbsp;&nbsp;
                <router-link class="box-btn white-hover" to="/feedback" title="Leave your feedback to help improve the game and overall player experience."> 
                        <i class="fa fa-comment"></i>
                </router-link>

                <span class="minimize float-right act" @click="minimizeGame">
                    <i class="fa fa-arrow-circle-left"></i>
                </span>&nbsp;

            </div>
            <OnlinePlayers :onlinee="onlinee" :showSetup="showOnliners" :friendsRequested="friends_requested" :friendsOnline="online" :gamesession="gameSession" v-if="showOnliners"> </OnlinePlayers>
            <LudiBoard :theme="user_theme" :online="online" :userActive=useractive> </LudiBoard>

    <button @click="notifyAlertout.play" ref="notify-alert-sound" class="soundfx"></button>

        </div>
    <Footer></Footer>
			
        </main>
  
</template>

<script>
    // @ is an alias to /src

    import OnlinePlayers from "@/components/OnlinePlayers.vue"; 
    import LudiBoard from "@/components/LudiBoard.vue"; 
    import Notifications from "@/components/Notifications.vue"; 
    import Footer from "@/components/Footer.vue"; 
    import { useSound } from '@vueuse/sound';
    import notifyAlertfx from '@/assets/sounds/NotifyAlert.mp3';
    import optionSelectfx from '@/assets/sounds/OptionSelect.mp3';

    export default {
        name: "Home",
        components: {
            Notifications,
            LudiBoard,
            OnlinePlayers,
            Footer
        }, setup(){
            const  notifyAlertout = useSound(notifyAlertfx);
            const  optionSelectout = useSound(optionSelectfx);
            return {
                notifyAlertout,optionSelectout
            };
        }, 
        data(){
        	return {
                gameSession: localStorage.getItem('gameSession') ? localStorage.getItem('gameSession') : null,
                playGame: localStorage.getItem('playStarted') ? localStorage.getItem('playStarted') : false,
                notifications:{"invites":[]},
                user_theme: localStorage.getItem('userTheme') ? localStorage.getItem('userTheme') : "default",
                loaded_themes: [],
                themes: [{"name":"default","thumbnail":"default.png"},{"name":"dark","thumbnail":"dark.png"},{"name":"divide","thumbnail":"divide.png"},{"name":"cooldee","thumbnail":"cooldee.png"}],
				newNotifs: false,
				showNotifs: false,
                gameInProgress: false,
				available: true,
				hasContent: false ,
				online: [],
                onlinee: [],
                friends_requested: [],
                showOnliners: true,
                useractive: true,
                activity_timer: 30,
                enableSettings: false
			}
        },
        mounted(){
        	let home_ = this;
            home_.useSelectedTheme(home_.user_theme);
            
			this.$sigcon.on("PlayerInvitation",function(){
                if(home_.$refs["notify-alert-sound"])
                    home_.$refs["notify-alert-sound"].click();

			    home_.$toast.info("You have an invitation. Check Notifications to accept.");
			    home_.$http.get("/game/GetPlayerInvitations").then(invites => {
			        home_.notifications["invites"] = invites.data.Data;
			        home_.newNotifs = true;
			    }).catch(()=>{

                });
			});
		   this.$sigcon.on("DeleteInvitation",function(){
                home_.$http.get("/game/GetPlayerInvitations").then(invites => {
                    home_.notifications["invites"] = invites.data.Data;
                    home_.newNotifs = true;
                }).catch(()=>{
                    
                });
            });

		   if(this.$user.IsAuthenticated){
                // setInterval(()=>{
                //     home_.activity_timer -= 1;
                    
                //     if(home_.activity_timer < 1)
                //         home_.user_active = false;

                //     if(home_.activity_timer == -300)
                //         window.location.reload();

                // },1000);

                window.addEventListener('connectionReady', () => {
                    let game_started = localStorage.getItem("playStarted");
                    if(this.$user.GuestUser && !game_started){
                        this.$sigcon.invoke("JoinAsGuest");
                        if(this.$refs["open-game-board"])
                            this.$refs["open-game-board"].click();
                    }
                });
            
                home_.$http.get("/game/GetPlayerInvitations").then(invites => {
                    home_.notifications["invites"] = invites.data.Data;
                }).catch(()=>{
                    //this.Logout();
                });
                
                home_.$http.get("/game/GetUnansweredFriendRequests").then(invites => {
                    home_.notifications["requests"] = invites.data.Data;
                    home_.friends_requested = invites.data.Data;
                });

                home_.$http.get("/game/GetFriends").then(friends => {
                    home_.$toast.clear();
                    home_.online = [];
                    let players = friends.data.Data;
                    players.forEach(function(player){
                        home_.online.push(player);    
                    });
                });

                this.$sigcon.on("PlayerUpdated",function(player){
                    home_.$toast.clear();
                    home_.online.forEach(function(person,ind){
                        if(person.FriendUserName == player.UserName){
                            home_.online[ind].PlayerStatus = player.PlayerStatus;
                            return;
                        }
                    });
                });

         

                this.$sigcon.on("RaiseError",function(error){
                    home_.$toast.error(error,{
                        duration: false,
                        dismissible: true,
                    });
                });
            }   
        },methods: {
        
            useSelectedTheme(board_theme){
                if(this.loaded_themes.indexOf(board_theme) == -1){
                    this.loaded_themes.push(board_theme);
                    let element = document.createElement("link");
                    element.setAttribute("rel", "stylesheet");
                    element.setAttribute("type", "text/css");
                    element.setAttribute("href", board_theme+".css");
                    document.getElementsByTagName("head")[0].appendChild(element);
                }
                if(this.user_theme != board_theme)
                    this.user_theme = board_theme;

                localStorage.setItem("userTheme", board_theme);
            },
            showSettings(){
                this.enableSettings = true;
            },
            closeSettings(){
                this.enableSettings = false; 
            },
            resetActivityTimer(){
                this.activity_timer = 30;
            },
        	addSubscriber(){
        		//console.log(`${this.subscriber} added to news letter.`)
        	},
        	loginToPlayLudi(){
                if(this.$route.name == 'Login' || this.$route.name == 'Register')
                    this.$toast.success("You are in the right place. Complete the process to start the awesomeness.");
                else
                    this.$toast.warning("<p>Login to experience the awesomeness.</p> <a class='btn btn-block btn-info' href='/login' ><i class='fa fa-sign-in-alt'></i></a>");
            },playLudi(){
                if(this.$user.IsAuthenticated){
                    this.playGame = true;
                    localStorage.setItem('playStarted',true);
                }else{
                    this.playGame = false;
                    localStorage.removeItem('playStarted');
                }
            },showNotifications(){
                this.newNotifs = false;
                this.showNotifs = true;
            },
            closeNotifications(){
                this.newNotifs = false;
                this.showNotifs = false;
            },changeStatus(status){
                this.available = true;
                if(status == 2)
                    this.available = false;

                this.$sigcon.invoke("UpdatePlayerStatus",status);
            }, 
            openOnliners(){
                this.showOnliners = true;
            },
            closeOnliners(){
                this.showOnliners = false;
                console.log("closing onliners");
            },
           
            handleHomeNav(e){
                e.preventDefault();
                this.playGame = false;
                localStorage.removeItem('playStarted');
                window.location.href = "/";
            },
            minimizeGame(){
                this.playGame = false;
                localStorage.removeItem('playStarted');
            },
            Logout(){
                let login_ = this;
                if(this.$user.IsAuthenticated){
                    this.$http.get('/user/Logout')
                     .then(() => {
                            login_.$toast.success("Logout in progress......");
                            localStorage.removeItem('user');
                            localStorage.removeItem('jwt');
                            localStorage.removeItem('token_expiry');
                            localStorage.removeItem('gameSession');
                            localStorage.removeItem('playStarted');
                            localStorage.removeItem('n_players');
                            login_.$user.IsAuthenticated = false;
                        setTimeout(function(){
                            window.location.reload();
                        },500);
                     })
                }
            }
        }
    };
</script>

<style scoped>
.in-use {
    cursor: cell;
}

.theme {
    width: 70px;
    height: 70px;
}

.theme img {
    width: 100%;
    height: 100%;
}

.use-theme:hover {
    cursor: pointer;
    border:  1px solid #BEFD36;
}

.in-use .theme-thumb {
    border: 2px dashed #08BCFF;
}

#settings {
    position: fixed;
    width: 100%;
    top: 9%;
    z-index: 10;
}

.soundfx {
    position: fixed;
    bottom: -100%;
}
    .act {
        cursor: pointer;
        border-radius: 50%;
        margin:  .5%;
        color: #FBDB00 !important;
    }
    .act:hover {
        color: #fff !important;
        /*box-shadow: 0px 0px 11px #FEFB08;*/
    }

    .htl {
        border-radius: 50%;
    }

	.htl:hover {
		cursor: pointer;
		/*border: 2px dashed #fc3;*/
        box-shadow: 0px 0px 11px rgba(255, 255, 255, 0.5);
	}

	.hero-paragraph {
		color: #8A94A7;
	}
    .white-hover:hover {
        color: #fc3;
    }
	.user-box {
		margin: 5%;
		position: absolute;
		z-index: 5;
		width: 60%;
	}
    
	.user-avatar-home {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-left: 1%;
        overflow: hidden;
    	border-radius: 50%;
    }

    .available {
    	float: left;
        border: none;
        outline: none;
        padding: 0% 3%;
        color:  #00adff!important;
        border-radius: 50%!important;
    }

    .available:hover {
        border: 2px dashed #fc3;
    }

    .offline {
    	float: left;
        border: none;
        outline: none;
        padding: 0% 3%;
        border-radius: 50%!important;
        color: #555!important;
    }

    .offline:hover {
        border: 1px dashed #555;
    }
    
    body{margin-top:20px;
color: #bcd0f7;
    background: #1A233A;
}
.account-settings .user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
}
.account-settings .user-profile .user-avatar {
    margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
    width: 90px;
    height: 90px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
    margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
}
.account-settings .about {
    margin: 1rem 0 0 0;
    font-size: 0.8rem;
    text-align: center;
}
.card {
    background: #272E48;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}
.form-control {
    border: 1px solid #596280;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #1A233A;
    color: #bcd0f7;
}
</style>