import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default {

install: (Vue) => {

    var event = new CustomEvent('connectionReady',{detail: true});

    let connection = new HubConnectionBuilder()
    .withUrl("https://ludijamapi.azurewebsites.net/gamecast")
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

    connection.start().then(() =>
    {
        window.dispatchEvent(event);
    })
    .catch(e => console.log("Error Init connection: ",e))

    Vue.config.globalProperties.$sigcon = connection;
}
};

 