<template>
    <main>
              <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">
                            <div class="login vertical-center">
        <div class="inner-block">
            <form>
                <h3>Forgot Password</h3>

                <div class="form-group">
                    <label>Email address</label>
                    <input type="email" class="form-control form-control-lg" />
                </div>

                <button type="submit" class="btn btn-dark btn-lg btn-block">Reset password</button>
                <p class="forgot-password text-right">
                    Already registered
                    <router-link :to="{name: 'Login'}">sign in?</router-link>&nbsp;
                
                    No Account
                    <router-link :to="{name: 'Register'}">Register?</router-link>
                </p>
            </form>
            </div>
    </div>
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06"></div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>
   <Footer></Footer>
    
</main>
</template>

<script>
    import Footer from "@/components/Footer.vue"; 

    export default {
        name: "ResetPassword",
        components: {
            Footer
        },
        data() {
            return {}
        }
    };
</script>