<template>
    <main>
         <section class="hero">
                <div class="container">
                    <div class="hero-inner">
                        <div class="hero-copy">
                            <div class="login vertical-center">
                                <div class="inner-block">
                                <form @submit.prevent="">
                                    <h3>LudiJam: Guest Join</h3>
                                    <h5 class="text-warning"> Invited by <span class="text-info">{{ inviter }}</span></h5>

                                    <div class="form-group">
                                        <label>User Name</label>
                                        <input type="text" ref="username_input" v-model="username" placeholder="Player Alias" class="form-control form-control-lg" required/>
                                    </div>

                                    <button type="button" @click="joinHandler" class="btn btn-dark btn-block p-2"><i class="fa fa-dice text-warning"></i> Join Game <i class="fa fa-dice text-warning"></i></button>
                            
                                </form>
                                </div>
                            </div>
                        </div>
                        <div class="hero-figure anime-element">
                            <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                                <rect width="528" height="396" style="fill:transparent;" />
                            </svg>
                            <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
                            <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
                            <!-- <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div> -->
                            <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
                            <div class="hero-figure-box hero-figure-box-05"></div>
                            <div class="hero-figure-box hero-figure-box-06"></div>
                            <div class="hero-figure-box hero-figure-box-07"></div>
                            <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
                            <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
                            <div class="hero-figure-box hero-figure-box-10" data-rotation="-50deg"></div>
                        </div>
                    </div>
                </div>
            </section>
   
    <button @click="optionSelectout.play" ref="option-select-sound" class="soundfx"></button>

<Footer></Footer>
</main>
</template>

<script>
    import { useMeta, getCurrentManager } from 'vue-meta';
    import Footer from "@/components/Footer.vue"; 
    import { useSound } from '@vueuse/sound';
    import notifyAlertfx from '@/assets/sounds/NotifyAlert.mp3';
    import optionSelectfx from '@/assets/sounds/OptionSelect.mp3';
    export default {
        name: 'Join',
        components: {
            Footer
        },
        setup(){
            useMeta({
              title: 'LudiJam - Join Game',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
            const  notifyAlertout = useSound(notifyAlertfx);
            const  optionSelectout = useSound(optionSelectfx);
            return {
                notifyAlertout,optionSelectout
            };
        },
        data(){
            return {
                username: "",
                password: "",
                inviter: "",
                gameSession: "",
                access: ""
            }
        },
        mounted(){
            let join_ = this;
              
            join_.gameSession = join_.getQueryParam("game");
            join_.inviter = join_.getQueryParam("inviter");
            join_.validateGameSession(join_.gameSession);  
        },
        methods: {
            validateGameSession(game){
                let join_ = this;
                join_.$http.get(`game/IsSessionActive/${game}`,join_.$httpOptions)
                .then((response)=>{
                    if(response.data.Data){
                        join_.$refs.username_input.focus();
                        localStorage.removeItem('playStarted');
                        join_.$toast.success("Enter your Alias to join game");
                    }else{
                        join_.$toast.error("Cannot Join. Game Session Invalid.");
                        join_.$router.push({name: "Login"});
                    }
                }).catch(()=>{
                    join_.$toast.error("Cannot Join. Game Session Invalid.");
                    join_.$router.push({name: "Login"});
                });
            },
            joinHandler(){
                let join_ = this;
                if(join_.username){
                    let joinee = {"UserName":join_.username,"SessionId":join_.gameSession};
                    join_.$refs['option-select-sound'].click();
                    
                    join_.$http.post("game/JoinGame",joinee,join_.$httpOptions)
                    .then((response)=>{
                        let user_in = response.data.Data.email;
                        let user = {UserId: user_in.UserId, UserName: user_in.UserName, IsAuthenticated: true,GuestUser:true, Email: `guest_user_${user_in.UserName.toLowerCase()}@ludijam.com`};

                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('gameSession', join_.gameSession);
                        localStorage.removeItem('playStarted');
                        

                        window.location.href ="/";
                    })
                    .catch(()=>{
                        join_.$router.push({name: "Login"});
                    })
                }else{
                    join_.$toast.error("User name field must be filled in.");
                }
            },
            getQueryParam(name, url = window.location.href) {
                name = name.replace(/[[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        }
    };
</script>