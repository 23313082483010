<template>
    <div class="admin">
       <h1>This will be Ludijam's admin page. A lot of stuff will be going here.</h1>
    </div>
</template>

<script>
    // @ is an alias to /src
   

    import { useMeta, getCurrentManager } from 'vue-meta';

    export default {
        name: "Admin",
 		setup(){
            useMeta({
              title: 'LudiJam - Admin',
              htmlAttrs: { lang: 'en', amp: true }
            },getCurrentManager());
        },
    };
</script>
