<template>
  <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people" v-if="isInbox">
          <!-- <div class="headind_srch">
            <div class="recent_heading">
              <h4>Recent</h4>
            </div>
            <div class="srch_bar">
              <div class="stylish-input-group">
                <input type="text" class="search-bar"  placeholder="Search" >
                <span class="input-group-addon">
                <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                </span> </div>
            </div>
          </div> -->
     <!--      <div class="inbox_chat">
            <div class="chat_list active_chat">
              <div class="chat_people">
                <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                <div class="chat_ib">
                  <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                  <p>Test, which is a new approach to have all solutions 
                    astrology under one roof.</p>
                </div>
              </div>
            </div>
            <div class="chat_list">
              <div class="chat_people">
                <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                <div class="chat_ib">
                  <h5>Sunil Rajput <span class="chat_date">Dec 25</span></h5>
                  <p>Test, which is a new approach to have all solutions 
                    astrology under one roof.</p>
                </div>
              </div>
            </div>
          </div> -->
        </div>

      <div class="mesgs">
          <div class="msg_history">
            <div v-for="message_ in groupMessages" :key="message_.id">
              <div v-if="message_.UserId == $user.UserId" :ref="message_.MessageId" class="message outgoing_msg">
                <div class="sent_msg">
                  
                  <p>{{ message_.Message }}</p>
                  <div v-if="message_.ReplyToId" class="message-preview" @click="goToMessage(message_.ReplyToId)">
                      <div class="reply_msg_preview">
                        <span v-if="groupMessages[message_.ReplyToId].UserId == $user.UserId">[You]</span>
                        <span v-else>[{{ groupMessages[message_.ReplyToId].UserName }}]</span>
                        <p>{{ groupMessages[message_.ReplyToId].Message }}</p>
                      </div>
                  </div>
                  <span class="time_date">{{ setDayTime(message_.DateSent) }} @ {{ moment(message_.DateSent).format('hh:mm') }} 
                    <span @click="replyToMessage(message_.MessageId)"><i class="fa fa-reply"></i></span>
                  </span>
                </div>
              </div>
              <div  v-else :ref="message_.MessageId" class="message incoming_msg">
                <div class="incoming_msg_img"> <img :src="message_.Avatar ? $imgPath + message_.Avatar : '/img/avatar.png'" alt="sunil"> </div>

                <div class="received_msg">
                  <div class="received_withd_msg">
                    <p>{{ message_.Message }}</p>
                     <div v-if="message_.ReplyToId" class="message-preview" @click="goToMessage(message_.ReplyToId)">
                        <div class="reply_msg_preview">
                          <span v-if="groupMessages[message_.ReplyToId].UserId == $user.UserId">[You]</span>
                          <span v-else>[{{ groupMessages[message_.ReplyToId].UserName }}]</span>
                          <p>{{ groupMessages[message_.ReplyToId].Message }}</p>
                        </div>
                    </div>
                    <span class="time_date">
                      <span class="name">{{ message_.UserName }}</span> 
                      {{ setDayTime(message_.DateSent) }} @ {{ moment(message_.DateSent).format('hh:mm') }}
                      <span @click="replyToMessage(message_.MessageId)"><i class="fa fa-reply"></i></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div id="last_message">
              <span>&nbsp;</span>
            </div>


              <div class="at-list" v-if="atTrigger">
              <div v-for="at in personsInGame" @click="addAddressMessageTo(at.UserName)" :key="at.id">
                <span v-if="at.UserId != $user.UserId" class="at-roomie">
                  <span class="at-img"><img :src="at.Avatar ? $imgPath + at.Avatar: '/img/avatar.png'"></span>
                  <span class="at-name">{{ at.UserName }}</span>
                </span>
              </div>
            </div>
           <div class="to-list" :title="to_who.join(',')">
              <span v-for="(who,index) in to_who" :key="who.id">
                <span>@{{ who }} <span @click="removeAt(index)"><i class="fa fa-times"></i></span></span>
              </span>
            </div>
          </div>
          <div class="type_msg">
            <div class="input_msg_write">
              <textarea class="write_msg" ref="chat_input" v-model="chat_message" @keyup.enter="sendChatMessage(true)" @keyup="sendTyping" placeholder="Type a message"> </textarea>
              <button class="msg_send_btn" type="button" :disabled="chat_message.length < 1" @click="sendChatMessage(false)"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
            </div>
            <div class="message-preview" v-if="replyTo" @click="goToMessage(replyTo)">
                <div class="reply_msg_preview">
                  <span @click="cancelReplyTo" class="cancel-reply float-left"><i class="fa fa-times"></i></span>
                  <span v-if="groupMessages[replyTo].UserId == $user.UserId">[You]</span>
                  <span v-else>[{{ groupMessages[replyTo].UserName }}]</span>
                  <p>{{ groupMessages[replyTo].Message }}</p>
                </div>
            </div>
          
            <span class="typer">{{ isTyping }}</span>
          </div>
      </div>
      </div>
    </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name:"Chat",
    props:["isInbox","groupMessages","isTyping","personsInGame"],
    created(){
      this.moment = moment;
    },
    data(){
      return {
        chat_message: "",
        audioSource: null,
        replyTo: null,
        atTrigger: false,
        combine_key: [],
        to_who: []
      }
    },mounted(){
  
        
    },methods: {
      removeAt(indx_){
        this.to_who.splice(indx_,1);
      },
      addAddressMessageTo(to_){
        if(to_){
          if(this.to_who.indexOf(to_) == -1)
            this.to_who.push(to_); 
        }
        
        this.chat_message = this.chat_message.slice(0, -1);
      },
      cancelReplyTo(){
          this.replyTo = null;
      },
      goToMessage(messId){
          let el_ = this.$refs[messId];
          if (el_) {
            el_.scrollIntoView({behavior: 'smooth'});
          }
      },
      handleRecording({ blob, src }) {
          console.log(blob);
          this.audioSource = src;
      },
      sendTyping(e){
        let key_ = e.which;
        if(key_ == 50 || key_ == 16)
          this.combine_key.push(1)
        else
            this.atTrigger = false;

        if(this.combine_key.length == 2){
            this.combine_key = [];
            this.atTrigger = true;
        }

        if(key_ != 13)
          this.$sigcon.invoke("InvokeTyping");
      },replyToMessage(messId){
          this.replyTo = messId;
      },
      sendChatMessage(on_enter){
        let chat_message = this.chat_message;
        let final_chat_message = "";
        let address = null;
        if(on_enter)
           this.chat_message = chat_message.slice(0, -1);

        if(this.chat_message != ""){
          final_chat_message = this.chat_message;
          if(this.to_who.length > 0)
            address = this.to_who.join(" ");

          if(address)
            final_chat_message = address + this.chat_message
          
          this.$sigcon.invoke("SendGroupMessage",{"Message":final_chat_message,"ReplyToId":this.replyTo});
          this.chat_message = "";
        }else{
          this.$toast.info("Can't send empty message.");
        }
        this.replyTo = null;

      },setDayTime(date_){
          let date__ = new Date(date_); 
          let this_date = moment(date__).format('DD.MM.YYYY');
          let today_ = moment().format('DD.MM.YYYY');
          let yesterday_ = moment().subtract(1,'days').format('DD.MM.YYYY');
         
          if(this_date === today_){
            return 'Today';
          }else{
            if(this_date === yesterday_)
              return 'Yesterday';
          }
          return this_date;
      }
    }
  };
</script>

<style scoped>
.to-list {
  position: absolute;
  bottom: 0%;
  left: 1%;
  width: 98%;
  font-size: 9px;
  font-weight: bold;
  background: transparent;
  text-align: left;
  color: #000;
}
.at-roomie {
  background: rgba(0,0,0,.5);
  border-radius: 3px;
  padding: 3px;
}

.at-list {
  position: absolute;
  width: 50%;
  height: 62vh;
  top: 7%;
  background: rgba(7,175,255,.7);
  padding: 1%;
}

.at-list .at-img{
  display: inline-block;
  width: 20%;
}

.at-list .at-name {
  display: inline-block;
  width: 70%;
  max-width: 70%;
  font-size: 9px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #fff;

}

.at-list .at-img img{
  width: 100%;
  border-radius: 50%;
}

.reply_msg_preview {
    background: rgba(10,10,10,.5);
    border-radius: 3px;
    padding: 0 5px;
}

.reply_msg_preview span{
  color: #fc3;
  font-size: 9px;
}

.reply_msg_preview p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: rgba(0,0,0,.5);
    font-size: 11px;
    color: #fff;
    text-align: left;
    padding: 2% 5%;
}

.message {
  margin-bottom: 2px;
}
.typer {
  position: absolute;
  font-size: 10px;
  color: #fff;
  bottom: 0;
}
.container{max-width:1170px; margin:auto;}
img{ max-width:100%; border-radius: 50%;}
.top_spac{ margin: 20px 0 0;}

.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.name {
  color: #d39e00;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg {
  background: rgba(0,0,0,.5) none repeat scroll 0 0;
  border-radius: 5px;
  padding: .5%;
}

.incoming_msg_img {
  display: inline-block;
  width: 5%;
  border-radius: 50%;
}
.received_msg {
  display: inline-block;
  vertical-align: middle;
  width: 90%;
 }
 .received_withd_msg p {
  /*background: #ebebeb none repeat scroll 0 0;*/
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  margin: 0;
  padding: 5px;
  width: 100%;
}
.time_date {
  color: #79adfb;
  display: block;
  font-size: 12px;
  margin: 2px;
}
/*.received_withd_msg { width: 90%;}*/
.mesgs {
  padding: 3%;
  width: 100%;
}

 .sent_msg p {
  /*background: #05728f none repeat scroll 0 0;*/
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:2px 0 2px;}

.sent_msg {
  background: rgb(5 54 143 / 75%) none repeat scroll 0 0;
  float: right;
  width: 70%;
  border-radius: 3px;

}

.input_msg_write textarea {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: none;
  outline: none;
  color: #fff;
  font-size: 15px;
  min-height: 48px;
  max-height: 50px;
  width: 80%;
  margin-right: 15%; 
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: absolute;
  width: 100%;
  bottom: 1%;
  left: 0;
}

.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 3%;
  top: 11px;
  width: 33px;
}

.messaging {
 position: absolute;
 width:50%;
 height: 80vh;
 right: 1%;
 padding: 0 0 50px 0;
 z-index: 15;
 background: rgba(0,0,0,.4);
}

.msg_history {
  height: 65vh;
  padding-bottom: 9%;
  overflow-y: auto;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}

.inbox_msg {
  clear: both;
  overflow: hidden;
}
</style>