<template>
<span v-if="!rolled" :style="'font-size:'+ size_" class="btn btn-default">
	<i :class="['fas fa-dice-'+  diceFaces[selectedFace], 'die', 'do-roll']"></i>
</span>&nbsp;&nbsp;
<span v-else :class="used ? 'disable' : ''">
	<i :style="'font-size:'+ size_" :class="['fas fa-dice-'+  diceFaces[rolled],'die']"></i>
</span>
</template>

<script>
/* eslint-disable */

	export default {
		name: "Dice",
		props:["roll","size","rolled","ref_","used"],
		data(){
			return {
                diceFaces: ["zero","one","two","three","four","five","six"],
				selectedFace: 3,
				size_: this.size ? this.size + 'px!important;': 'inherit',
				roll_: this.roll ? true: false,
			}
		},mounted(){
			let dice_ = this;
			let change_interval = setInterval(()=>{
				dice_.changeFace();
				if(dice_.rolled)
					clearInterval(change_interval);
			},500);
		},
		methods:{
			changeFace(){
				let roll_ = parseInt((Math.random() * 6)) +1;
				this.selectedFace = roll_;
			}
		}
	};
</script>

<style scoped>
	
	.die {
		background: #ddd;
	    padding: 0px 2px;
	    margin-right: 7px;
	    border-radius: 5px;
	}

	.disable {
		color: #999;
	}


</style>