 <template>
<div v-if="showSetup">
 <div class="setup_" v-if="setup">
    <h3>Game Setup <span class="closeSetup float-right" @click="closeSetup"><i class="fa fa-times"></i></span></h3>

    <h1>&nbsp;</h1>
    <h5>Number of players?</h5>
    <button @click="initLudiGame(2)" ref="init1" class="btn btn-info">
        2 <i class="fa fa-users"></i>
    </button>&nbsp;
    <button @click="initLudiGame(3)" ref="init2" class="btn btn-warning">
        3 <i class="fa fa-users"></i>
    </button>&nbsp;
    <button @click="initLudiGame(4)" ref="init3" class="btn btn-success">
        4 <i class="fa fa-users"></i>
    </button>&nbsp;
</div>
<div class="the-players">
 <div class="inviter" v-if="invitePlayers">
            <span @click="closeInviter" class="float-right text-white">
                <i class="fa fa-times"></i>
            </span>
            <h3>Invite Players </h3>
            
            <h4> By invite link
            <div class="input-group col-md-12">
                  <input class="form-control" type="text" :value="invite_link" id="invite-link">
                  <span class="input-group-append">&nbsp;&nbsp;
                    <button class="btn btn-small text-white" type="button" @click="copyInviteLink">
                        <i class="fa fa-copy" ></i>
                    </button>
                  </span>
            </div>
           </h4>
          
            <h4>By email <span class="email-adder" @click="addInvitee"><i class="fa fa-plus"></i></span></h4>
            <form v-if="invite_list.length > 0">
                <div v-for="i in invite_list" :key="'invite_'+ i">
                    <div class="input-group col-md-12">
                        <input type="email" name="invites[]" :ref="'invite_'+ i" v-model="invitees" @change="verifyInvitee('invite_'+ i)" class="form-control" required />
                        <span class="input-group-append">&nbsp;&nbsp;
                            <button class="btn btn-small text-white" type="button" @click="removeInput(i)">
                                <i class="fa fa-times"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <br/>
                <button type="submit" @click="handleInvites" class="btn btn-primary btn-block p-2">Send Invite</button>
            </form>
        </div>
    
    <div class="online_players">
           <div v-if="!$user.GuestUser"> 
                <div v-if="!gameSession"> 
                    <button type="button" @click="startGameSession" class="btn btn-block btn-success p-2"><i class="fa fa-dice text-warning"></i> Start Game
                        <i class="fa fa-dice text-warning"></i>
                    </button>
               </div>
               <div v-else>
                    <div v-if="n_players">
                        <button type="button" @click="showInviter" :disabled="invitePlayers" class="btn btn-warning btn-block p-2"><i class="fa fa-share-square text-white"></i> Invite Players <i class="fa fa-share-square text-white"></i></button>
                    </div>
               </div>
            </div>
            <div v-else>
                <button type="button" @click="showRegister" class="btn btn-warning btn-block p-2"><i class="fa fa-users text-white"></i> Member Signup <i class="fa fa-users text-white"></i></button>
            </div>
            
            <h3>Friends Online <span v-if="!$user.GuestUser" class="btn btn-default text-white cursor-pointer" title="Add friends" @click="addFriends()"><i class="fa fa-plus"></i><i class="fa fa-users fa-2x"></i></span></h3>
            <div class="container mt-3 justify-content-center" v-for="friend in friendsOnline" :key="friend.FriendUserName">
                <div class="card p-1" v-if="$user.UserName != friend.FriendUserName">
                    <div class="d-flex align-items-center">
                    <div class="image w-30">
                        <img :alt="friend.FriendUserName + '_ludijame_avatar'" :src=" friend.FriendAvatar ? $imgPath + friend.FriendAvatar : '/img/avatar.png'" class="round" width="50" height="50"> 
                    </div>
                        <div class="ml-3 w-100">
                           <span class="status float-right"> 
                                <span :class="'indicator ' + colorIndicators[status[friend.PlayerStatus]]">
                                    <i class="fa fa-circle"></i>
                                </span>
                                {{ status[friend.PlayerStatus] }}
                            </span>
                            <h5 class="mb-0 mt-0" :title="friend.FriendUserName">
                               <span class="name"> {{ friend.FriendUserName }} </span>
                                <button class="btn btn-default text-green float-right" v-if="status[friend.PlayerStatus] == 'available' && gameSession && n_players && !friend.GuestUser" @click="onlinePlayerInvite(friend)" :title="'Invite ' +friend.FriendUserName+' to a this game'">
                                    <i class="fa fa-share-square"></i>
                                </button> 
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <div class="online_players" v-if="getFriends && !$user.GuestUser">
        <span @click="cancelAddFriends" title="Close add friends dialog" class="float-left text-white cursor-pointer">
            <i class="fa fa-times"></i>
        </span>
        <div class="input-group col-md-12">
              <input class="form-control" type="text" v-model="friendQ" @keydown="queryFriend" @change="queryFriend" placeholder="Search: [username]" id="friend">
              <span class="input-group-append">&nbsp;&nbsp;
                <button class="btn btn-small text-white" type="button"  @click="queryFriend">
                    <i class="fa fa-search" ></i>
                </button>
              </span>
        </div>
        <div v-if="friendsFound">
            <h3>Friends</h3>
            <div class="container mt-3 justify-content-center" v-for="friend in friendsFound" :key="friend.UserName">
                <div class="card p-1" v-if="$user.UserName != friend.UserName">
                    <div class="d-flex align-items-center">
                        <div class="image w-30">
                            <img :alt="friend.UserName + '_ludijame_avatar'" :src=" friend.Avatar ? $imgPath + friend.Avatar : '/img/avatar.png'" class="round" width="50" height="50"> 
                        </div>
                        <div class="ml-3 w-100">
                           <span class="status float-right"> 
                                <span :class="'indicator ' + colorIndicators[status[friend.PlayerStatus]]">
                                    <i class="fa fa-circle"></i>
                                </span>
                                {{ status[friend.PlayerStatus] }}
                            </span>
                            <h5 class="mb-0 mt-0" :title="friend.UserName">
                               <span class="name"> {{ friend.UserName }} </span>
                                <button v-if="!friend.alreadyFriend" class="btn btn-default text-green float-right" @click="sendFriendRequest(friend)" :title="'Send friend request to' +friend.UserName+' '">
                                    <i class="fa fa-plus"></i>
                                </button> 
                                    
                                <button v-else class="btn btn-default text-green float-right" title="Already Friends" disabled><i class="fa fa-check-square"></i></button>
                            </h5>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h3>Friends</h3>
            <div class="container mt-3 justify-content-center">
                <div class="card p-1">
                    <div class="d-flex align-items-center">
                        <p>No result</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
    </div>
        <button @click="optionSelectout.play" ref="option-select-sound" class="soundfx"></button>

</template>

<script>
/* eslint-disable */
    import { useSound } from '@vueuse/sound';
    import { uuid } from 'vue-uuid'; 
    import MD5 from 'md5';

    import optionSelectfx from '@/assets/sounds/OptionSelect.mp3';
    export default {
        name: "OnlinePlayers",
        props: ["friendsOnline","friendsRequested","gamesession","showSetup","onlinee"],
        setup(){
            const  optionSelectout = useSound(optionSelectfx);
            return {
                optionSelectout
            };
        },
        data(){
            return {
                status:["","online","offline","ingame","available","disconnected"],
                colorIndicators: {"online":"green","ingame":"grey","available":"blue","offline":"red","disconnected":"orange"},
                gameSession: localStorage.getItem('gameSession') ? localStorage.getItem('gameSession') : null,
                user: null,
                setup: false,
                invite_link: "",
                invite_list: [],
                invitees: [],
                invite_success: [],
                invite_rejected: [],
                friendsFound: [],
                invitePlayers: false,
                getFriends: false,
                friendQ: "",
                n_players: localStorage.getItem('n_players') ? parseInt(localStorage.getItem('n_players')) : null,
            }
        },created(){
            
        },
        mounted(){
            let online_ = this;
            this.invite_link = 'https://'+ window.location.hostname + "/#/join?game=" + this.gameSession +"&inviter="+this.$user.UserName;
        },
        methods: {
            addFriends(){
                this.getFriends = true;
            },
            cancelAddFriends(){
                this.getFriends = false;
            },
            sendFriendRequest(friend){
                let online_ = this;
                this.$http.post("/game/AddFriend",friend,this.$httpOptions)
                .then(()=>{
                    online_.$toast.success(`Friend request has been sent to ${friend.UserName}`);
                })
                .catch(e=>{
                    if(e.response)
                        online_.$toast.error(`${e.response.data}`);
                    else
                        online_.$toast.error(`${e}`);
                })
            },
            queryFriend(){
                let online_ = this;
                if(this.friendQ.length >= 3){
                    this.$http.get(`/game/QueryUsersByUsername/${this.friendQ}`)
                    .then((response)=>{
                        let tential_friends = response.data;
                        let found_list = [];
                        online_.friendsFound = tential_friends;
                        online_.friendsOnline.forEach(friend=>{
                            tential_friends.forEach(p_friend=>{
                                if(friend.FriendUserName == p_friend.UserName)
                                    p_friend['alreadyFriend'] = true;

                                let in_friend_list = online_.friendsFound.some(f=> f.UserName == p_friend.UserName)
                                if(p_friend.UserName != this.$user.UserName && !in_friend_list)
                                    online_.friendsFound.push(p_friend)
                            })
                        })
                    })
                    .catch(e=>{
                        if(e.response)
                            online_.$toast.error(`${e.response.data}`);
                        else
                            online_.$toast.error(`${e}`);
                    })
                }else{
                    online_.friendsFound = [];
                }
            },
            showRegister(){
                this.$router.push({name:'Register'});
            },
            startGameSession(){
                this.$refs['option-select-sound'].click();
                this.setup = true;
                this.gameSession = uuid.v1();
                localStorage.setItem('gameSession', this.gameSession);
            },
            closeSetup(){
                this.$refs['option-select-sound'].click();
                this.setup = false;
                this.gameSession = null;
                this.$toast.show("<i class='fa fa-spin'></i> Game Cancelled");
                this.$sigcon.invoke("DeleteGameSession");
                localStorage.removeItem('n_players');
                localStorage.removeItem('gameSession');
                window.location.reload();
            },
            initLudiGame(n_players) {
                this.$refs['option-select-sound'].click();

                this.setup = false;
                this.sessionStatus = 1;
                this.$refs.init1.disabled = true;
                this.$refs.init2.disabled = true;
                this.$refs.init3.disabled = true;
                this.n_players = n_players;
                localStorage.setItem('n_players', n_players);
                this.invite_link = 'https://'+ window.location.hostname + "/#/join?game=" + this.gameSession +"&inviter="+this.$user.UserName;
                this.$sigcon.invoke("CreateGameSession", {"SessionId": this.gameSession,"NumOfPlayers": n_players});
            }, 
            showInviter(){
                this.$refs['option-select-sound'].click();
                this.invitePlayers = true;
            },
            closeInviter(){
                this.$refs['option-select-sound'].click();
                this.invitePlayers = false;
            },
            removeInput(i){
                this.invite_list.splice(i,1);
            },
            addInvitee(){
                this.$refs['option-select-sound'].click();
                this.invite_list.push(this.invite_list.length);
            },
            verifyInvitee(ref){
                var online_ = this; 
                if(online_.$refs[ref].value == this.$user.Email){
                    online_.$toast.error("You can't invite yourself to a game.");
                }else{
                    if(this.invite_success.length > 0){
                        this.invite_success.forEach(function(email){
                            if(online_.$refs[ref].value == email){
                                online_.$toast.error(email+" has already been invited.");
                                online_.$refs[ref].value = "";
                            }
                        });
                    }
                }
            },
            onlinePlayerInvite(player){
                this.$refs['option-select-sound'].click();
                let invite_msg = `Hey ${player.FriendUserName}, you are being invited to a ${this.n_players} player game by ${this.$user.UserName}`;
                let online_ = this;
                this.$sigcon.invoke("SendPlayerInvitation", {"UserId": player.FriendId, "SessionId":this.gameSession,"Description":invite_msg}).then(()=>{
                    online_.$toast.warning(`Sending invite to ${player.FriendUserName}..........`);

                });
            },
            handleInvites(e){
                e.preventDefault();
                let invite_params = {"Url":"https://"+window.location.hostname,"Recipients":[this.invitees],"Message":`You are being invited to a ludi game on ludijam.com by ${this.$user.UserName}`,"NameMask":this.$user.UserName,"SessionId":this.gameSession };
                let online_ = this;
                this.$http.post("game/SendInvitations",invite_params,this.$httpOptions)
                .then((response)=>{
                   this.invite_success = response.Data.Success;
                   this.invite_rejected = response.Data.Rejected;

                    online_.$toast.success("Invitation has been sent. Waiting for guests to join");
                })
                .catch(e=>{
                    if(e.response)
                        online_.$toast.error(`${e.response.data}`);
                    else
                        online_.$toast.error(`${e}`);
                })
            },
            copyInviteLink(){
                this.$refs['option-select-sound'].click();
                let linkToCopy = document.querySelector('#invite-link');
                linkToCopy.select()

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    this.$toast.success("Invite link copied to clipboard "+msg);
                } catch (err) {
                    this.$toast.error("Could not copy link");
                }

                window.getSelection().removeAllRanges()
            },
        }
    };
</script>

<style scoped>
.soundfx {
    position: fixed;
    bottom: -100%;
}
.email-adder {
    cursor: pointer;
}

.closeSetup {
    cursor: pointer;
    margin-top: -9%;
    margin-right: 5%;
}

.the-players {
    position: absolute;
    z-index: 8;
    width: 100%;
}
.indicator {

}
.status {
    color: #999;
    font-size: 9px;
}
.name {
    display: inline-block;
    width: 80%;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.green {
    color: green;
}
.blue {
    color: #00adff;
}
.orange {
    color: orange;
}
.red {
    color: red;
}

.grey {
    color: #031b35;
}

.setup_ .btn {
    border-radius: 4px !important;
    padding: 1% 3%;
}

</style>